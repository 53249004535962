export const nalogTypeOptions = [
  {
    key: "IP",
    text: "ИП",
    value: "IP"
  },
  {
    key: "SZ",
    text: "СЗ",
    value: "SZ"
  },
  {
    key: "GPX",
    text: "ГПХ",
    value: "GPX"
  }
];

export const genderOptions = [
  {
    key: "M",
    text: "Мужской",
    value: "M"
  },
  {
    key: "F",
    text: "Женский",
    value: "F"
  }
];

export const statusOptions = [
  {
    key: "ASSISTENT",
    text: "Assistent",
    value: "ASSISTENT"
  },
  {
    key: "ADVISOR",
    text: "Advisor",
    value: "ADVISOR"
  },
  {
    key: "RECOMMENDER",
    text: "Recommender",
    value: "RECOMMENDER"
  },
  {
    key: "OPINION LEADER",
    text: "Opinion Leader",
    value: "OPINION LEADER"
  },
  {
    key: "CONCEPT SPECIALIST",
    text: "Concept Specialist",
    value: "CONCEPT SPECIALIST"
  }
];

export const paymentOptions = [
  {
    key: 1,
    value: 1,
    text: "1 единовременный платеж"
  },
  {
    key: 2,
    value: 2,
    text: "2 месяца"
  },
  {
    key: 3,
    value: 3,
    text: "3 месяца"
  },
  {
    key: 4,
    value: 4,
    text: "4 месяца"
  },
  {
    key: 5,
    value: 5,
    text: "5 месяцев"
  },
  {
    key: 6,
    value: 6,
    text: "6 месяцев"
  },
  {
    key: 7,
    value: 7,
    text: "7 месяцев"
  },
  {
    key: 8,
    value: 8,
    text: "8 месяцев"
  },
  {
    key: 9,
    value: 9,
    text: "9 месяцев"
  },
  {
    key: 10,
    value: 10,
    text: "10 месяцев"
  },
  {
    key: 11,
    value: 11,
    text: "11 месяцев"
  },
  {
    key: 12,
    value: 12,
    text: "12 месяцев"
  },
  {
    key: 13,
    value: 13,
    text: "13 месяцев"
  },
  {
    key: 14,
    value: 14,
    text: "14 месяцев"
  },
  {
    key: 15,
    value: 15,
    text: "15 месяцев"
  },
  {
    key: 16,
    value: 16,
    text: "16 месяцев"
  },
  {
    key: 17,
    value: 17,
    text: "17 месяцев"
  },
  {
    key: 18,
    value: 18,
    text: "18 месяцев"
  }
];

export const monthOptions = [
  {
    key: 1,
    value: 1,
    text: "Январь"
  },
  {
    key: 2,
    value: 2,
    text: "Февраль"
  },
  {
    key: 3,
    value: 3,
    text: "Март"
  },
  {
    key: 4,
    value: 4,
    text: "Апрель"
  },
  {
    key: 5,
    value: 5,
    text: "Май"
  },
  {
    key: 6,
    value: 6,
    text: "Июнь"
  },
  {
    key: 7,
    value: 7,
    text: "Июль"
  },
  {
    key: 8,
    value: 8,
    text: "Август"
  },
  {
    key: 9,
    value: 9,
    text: "Сентябрь"
  },
  {
    key: 10,
    value: 10,
    text: "Октябрь"
  },
  {
    key: 11,
    value: 11,
    text: "Ноябрь"
  },
  {
    key: 12,
    value: 12,
    text: "Декабрь"
  }
];
