import moment from "moment";
import React from "react";
import {passportType} from "../../../../common/APIRequests/passport/getPassports";
import PickerDate from "../../../common/formFields/PickerDate";
import StringField from "../../../common/formFields/StringField";
import {sanitizeInt} from "../../../common/formFields/sanitizers";
import {errorType} from "../../../common/types";

type Props = {
  values: passportType;
  handleChange: Function;
  errors: errorType;
  setErrors: (error: errorType) => void;
  loading: boolean;
  isClient?: string;
};

const PassportInputs = (props: Props) => {
  const {values, handleChange, errors, setErrors, loading, isClient} = props;

  return (
    <React.Fragment>
      <StringField
        value={values.passport_series}
        setValue={handleChange("passport_series")}
        name="passport_series"
        label="Серия паспорта"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 4}}
        disabled={loading || (Boolean(values.id) && isClient === "1")}
        required
        sanitizer={sanitizeInt}
      />
      <StringField
        value={values.passport_number}
        setValue={handleChange("passport_number")}
        name="passport_number"
        label="Номер паспорта"
        errors={errors}
        setErrors={setErrors}
        inputProps={{maxLength: 6}}
        disabled={loading || (Boolean(values.id) && isClient === "1")}
        required
        sanitizer={sanitizeInt}
      />
      <StringField
        value={values.passport_by}
        setValue={handleChange("passport_by")}
        name="passport_by"
        label="Паспорт выдан"
        errors={errors}
        setErrors={setErrors}
        multiline
        disabled={loading || (Boolean(values.id) && isClient === "1")}
        required
      />
      <PickerDate
        value={values.passport_date}
        setValue={handleChange("passport_date")}
        name="passort_date"
        label="Дата выдачи паспорта"
        maxDate={moment().toDate()}
        disabled={loading || (Boolean(values.id) && isClient === "1")}
      />
      <StringField
        value={values.passport_address}
        setValue={handleChange("passport_address")}
        name="passport_address"
        label="Адрес по прописке"
        errors={errors}
        setErrors={setErrors}
        multiline
        disabled={loading || (Boolean(values.id) && isClient === "1")}
        required
      />
    </React.Fragment>
  );
};

export default PassportInputs;
