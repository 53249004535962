import {
  DataTypeProvider,
  IntegratedSorting,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableColumnResizing,
  TableColumnVisibility,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {productType} from "../../../../../common/APIRequests/product/getProducts";
import {useLocalStorage} from "../../../../../common/customHooks";
import {
  StyledTableHeaderRow,
  StyledVirtualTable
} from "../../../../common/grid/cell";
import {columnType, sortingType} from "../../../../common/types";
import CountInput from "./CountInput";
import TotalLine from "./TotalLine";

const columns: columnType = [
  {name: "vendor_code", title: "Артикул"},
  {name: "name", title: "Наименование"},
  {name: "cost", title: "Цена €"},
  {name: "rur", title: "Цена ₽"},
  {name: "count", title: "Кол-во"},
  {name: "remove", title: "Действие"}
];

const columnExtensions = [
  {columnName: "vendor_code", width: 100, minWidth: 80},
  {columnName: "name", width: "auto", minWidth: 130},
  {columnName: "cost", width: 80, minWidth: 50},
  {columnName: "rur", width: 80, minWidth: 50},
  {columnName: "count", width: 90, minWidth: 90},
  {columnName: "remove", width: 90, minWidth: 90}
];

type Props = {
  selectedProducts: Array<productType>;
  onProductDeselect: Function;
  onProductCountUpdate: Function;
};

const BuyList = (props: Props) => {
  const {selectedProducts, onProductDeselect, onProductCountUpdate} = props;

  const [rows, setRows] = useState(selectedProducts);

  useEffect(() => {
    setRows(selectedProducts);
  }, [selectedProducts]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState(["rur"] as Array<
    string
  >);

  const [sorting, setSorting] = useLocalStorage<sortingType>(
    "buy_list_sorting",
    [{columnName: "name", direction: "desc"}]
  );

  const RemoveFormatter = (data: any) => {
    return (
      <Button onClick={() => onProductDeselect(data.row.id)}>Убрать</Button>
    );
  };

  const RemoveProvider = (props: any) => (
    <DataTypeProvider formatterComponent={RemoveFormatter} {...props} />
  );

  const CountFormatter = (data: any) => {
    const product = data.row as productType;
    return (
      <CountInput
        onProductCountUpdate={onProductCountUpdate}
        product={product}
        products={rows}
      />
    );
  };

  const CountProvider = (props: any) => (
    <DataTypeProvider formatterComponent={CountFormatter} {...props} />
  );

  rows.sort((first: productType, second: productType) => {
    let comparison = 0;
    if (first.vendor_code > second.vendor_code) {
      comparison = 1;
    } else if (first.vendor_code < second.vendor_code) {
      comparison = -1;
    }
    return comparison;
  });

  return (
    <React.Fragment>
      <Grid columns={columns} rows={rows} getRowId={row => row.id}>
        <StyledVirtualTable
          rowComponent={({row, ...restProps}: any) => (
            <VirtualTable.Row {...restProps} />
          )}
          height="30vh"
          wrapWrapEnabled
          wrap
        />

        {/* Sorting*/}
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />

        {/* Column Visibility */}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        {/*Column Resizing*/}
        <TableColumnResizing
          defaultColumnWidths={columnExtensions}
          resizingMode="nextColumn"
          columnExtensions={columnExtensions}
        />

        <RemoveProvider for={["remove"]} />
        <CountProvider for={["count"]} />

        {/* Rows */}
        <StyledTableHeaderRow />
      </Grid>
      {rows.length > 0 && <TotalLine selectedProducts={rows} />}
    </React.Fragment>
  );
};

export default BuyList;
