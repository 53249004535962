import CloseIcon from "@mui/icons-material/Close";
import {DialogTitle, DialogTitleProps, IconButton, Theme} from "@mui/material";
import {withStyles} from "@mui/styles";
import {makeStyles} from "tss-react/mui";
import theme from "../../../theme";

const useStyles = makeStyles()((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}));

const StyledDialogTitle = withStyles({
  root: {
    padding: `${theme.spacing(6)} ${theme.spacing(3)} 0`
  }
})(DialogTitle);

type extraType = {
  onClose?: Function;
};

const DraggableDialogTitle = ({...props}: DialogTitleProps & extraType) => {
  const {onClose} = props;
  const {classes} = useStyles();
  return (
    <StyledDialogTitle {...props} style={{cursor: "move"}}>
      {props.children}
      {onClose && (
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose && onClose()}
        >
          <CloseIcon />
        </IconButton>
      )}
    </StyledDialogTitle>
  );
};

export default DraggableDialogTitle;
