import {errorType} from "../../../components/common/types";
import {apiClient} from "../../utils/apiClient";

export function baseDeleteRequest(
  url: string,
  data?: undefined | any
): Promise<{
  success: boolean;
  errors: errorType;
}> {
  return new Promise(resolve =>
    apiClient({
      method: "delete",
      url,
      data
    })
      .then(response =>
        resolve({
          success: true,
          errors: {}
        })
      )
      .catch(errors => {
        resolve({
          success: false,
          errors
        });
      })
  );
}
