/** backend address */
export const Proxy: string = `https://backend.myleomaxinternational.com`;

// test properties
export const TEST_USER = "ancel";
export const TEST_PASSWORD = "PDVAncel115648";

export const appVersion = "2.00";

export const buildNumber: string = `${
  process.env.TEAMCITY_BUILD_NUMBER
    ? process.env.TEAMCITY_BUILD_NUMBER
    : "!this will be a build number"
}`;

// pagination
export const pageSizes = [10, 30, 50];
