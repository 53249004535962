import React, {useContext, useState} from "react";

import {LockOutlined} from "@mui/icons-material";
import {
  Avatar,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import postLogin from "../../common/APIRequests/login/postLogin";
import theme from "../../theme";
import {AppContext} from "../App-Context";
import ButtonLoading from "../common/buttons/ButtonLoading";
import {alertOrError} from "../common/functions";
import {errorType} from "../common/types";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    marginTop: "12vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3)
  }
}));

type Props = {
  userLogin: Function;
};

const LoginForm = (props: Props) => {
  const {userLogin} = props;

  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [values, setValues] = useState({
    username: "",
    password: ""
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (e: any) => {
    delete errors[name];
    setValues({...values, [name]: e.target.value});
  };

  const handleSubmit = async (e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    let errors = {};

    !values.username &&
      (errors = {
        ...errors,
        username: "Не заполнено имя пользователя"
      });

    !values.password && (errors = {...errors, password: "Не заполнен пароль"});

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    const result = await postLogin(values);
    setLoading(false);

    if (result.success) {
      result.row && userLogin(result.row);
    } else {
      errors = result.errors;
      alertOrError(setAlert, errors, setErrors);
    }
  };

  const passwordRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [buttonRef, setButtonRef] = useState(
    undefined as HTMLElement | undefined
  );

  const handleEnter = (name: string) => (e: any) => {
    if (e.key === "Enter") {
      if (name === "login") {
        passwordRef.current && passwordRef.current.focus();
      }
      if (name === "password") {
        buttonRef && buttonRef.focus();
        buttonRef && buttonRef.click();
      }
    }
  };

  const {classes: localClasses} = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={localClasses.paper}>
        <Avatar className={localClasses.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
          Добро пожаловать в компанию <br /> Leomax International!
        </Typography>
        <Typography
          component="h1"
          variant="subtitle1"
          style={{textAlign: "center"}}
        >
          Для начала работы вам необходимо авторизоваться
        </Typography>
        <form className={localClasses.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl error={!!errors.username} fullWidth>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Имя пользователя"
                  name="username"
                  autoComplete="username"
                  placeholder="Ваше имя пользователя"
                  value={values.username}
                  error={!!errors.username}
                  aria-describedby="component-error-username"
                  disabled={loading}
                  onChange={handleChange("username")}
                  onKeyPress={handleEnter("login")}
                />
                {errors.username && (
                  <FormHelperText id="component-error-username">
                    {errors.username}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={!!errors.password} fullWidth>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="Пароль"
                  name="password"
                  inputProps={{
                    ref: passwordRef
                  }}
                  autoComplete="password"
                  placeholder="Ваш пароль"
                  type="password"
                  value={values.password}
                  error={!!errors.password}
                  aria-describedby="component-error-password"
                  disabled={loading}
                  onChange={handleChange("password")}
                  onKeyPress={handleEnter("password")}
                />
                {errors.password && (
                  <FormHelperText id="component-error-password">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <FormHelperText
              id="component-error-text"
              className={theme.palette.error.main}
              error={!!errors.non_field_errors}
            >
              {errors.non_field_errors}
            </FormHelperText>
            <Grid item xs={12}>
              <ButtonLoading
                loading={loading}
                fullWidth={true}
                color="primary"
                submit={handleSubmit}
                type="submit"
                setRef={setButtonRef}
              >
                Авторизироваться
              </ButtonLoading>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LoginForm;
