import {Button, DialogActions, DialogContent} from "@mui/material";
import {RefObject, createRef, useEffect, useState} from "react";
import {Transition} from "../../../common/components";
import DraggableDialog from "../../../common/dialog/DraggableDialog";
import DraggableDialogTitle from "../../../common/dialog/DraggableDialogTitle";
import StringField from "../../../common/formFields/StringField";
import {errorType} from "../../../common/types";

type Props = {
  modeModalOpen: boolean;
  setSelectedMode: Function;
  setModeModalOpen: Function;
  errors: errorType;
  setErrors: Function;
  loading: boolean;
  handlePromoCodeSubmit: Function;
};

const PromoCodeForm = (props: Props) => {
  const {
    modeModalOpen,
    setSelectedMode,
    setModeModalOpen,
    errors,
    setErrors,
    loading,
    handlePromoCodeSubmit
  } = props;

  const [promoCode, setPromoCode] = useState("" as string);

  const promoCodeRef: RefObject<HTMLInputElement> = createRef();
  useEffect(() => {
    promoCodeRef.current && promoCodeRef.current.focus();
  }, [promoCodeRef]);

  return (
    <DraggableDialog
      open={modeModalOpen}
      // onClose={() => {
      //   setModeModalOpen(false);
      //   setSelectedMode(undefined);
      // }}
      TransitionComponent={Transition}
      aria-labelledby="mode-modal"
    >
      <DraggableDialogTitle id="mode-modal">
        Введите промокод
      </DraggableDialogTitle>
      <DialogContent>
        <StringField
          setValue={(value: string) => setPromoCode(value)}
          value={promoCode}
          name="promoCode"
          label="Промокод"
          errors={errors}
          setErrors={setErrors}
          inputProps={{
            maxLength: 200,
            ref: promoCodeRef
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={() => !loading && handlePromoCodeSubmit(promoCode)}
        >
          Отправить
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

export default PromoCodeForm;
