export function sanitizeInt(value: string) {
  value = value.replace(/\D/g, "");

  // remove leading zeros
  // value = value.replace(/^0+(?=\d)/, "");

  return value;
}

export function sanitizeFloat(value: string) {
  // remove leading zeros
  value = value.replace(/^0+(?=\d)/, "");
  // replace comma with dot
  value = value.replace(",", ".");
  // allow only digits and dot
  value = value.replace(/[^0-9.]/g, "");
  // prevent single dot
  value === "." && (value = "");

  // prevent multiple dots
  if (value) {
    const parts = value.split(".");
    parts.length > 1 && (value = parts.shift() + "." + parts.join(""));
  }
  return value;
}

export const validatePhone = (text: string | null) => {
  const re = /^[0-9]{10}$/im;
  const result = re.test(String(text).toLowerCase());
  return result;
};
