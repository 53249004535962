import {Button} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";
import {PropsWithChildren} from "react";

type PrimaryButtonProps = {
  title?: string;
} & ButtonProps;

export const PrimaryButton = (props: PropsWithChildren<PrimaryButtonProps>) => {
  return (
    <Button variant="contained" color="secondary" {...props}>
      {props.title || props.children}
    </Button>
  );
};
