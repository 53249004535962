import moment from "moment";
import React from "react";
import "react-phone-input-2/lib/material.css";
import {potentionalClientType} from "../../../common/APIRequests/potentionalClient/getPotentionalClients";
import PhoneField from "../../common/formFields/PhoneField";
import PickerDate from "../../common/formFields/PickerDate";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import StringField from "../../common/formFields/StringField";
import {genderOptions} from "../../common/options";
import {errorType} from "../../common/types";

type Props = {
  values: potentionalClientType;
  handleChange: Function;
  errors: errorType;
  setErrors: (errors: errorType) => void;
  loading: boolean;
  isClient?: string;
};

const PotentionalClientInputs = (props: Props) => {
  const {values, handleChange, errors, setErrors, loading, isClient} = props;

  return (
    <React.Fragment>
      <StringField
        setValue={handleChange("last_name")}
        value={values.last_name}
        name="last_name"
        label="Фамилия"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || Boolean(values.id) || isClient === "1"}
        inputProps={{maxLength: 50}}
        required
      />
      <StringField
        setValue={handleChange("first_name")}
        value={values.first_name}
        name="first_name"
        label="Имя"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || Boolean(values.id) || isClient === "1"}
        inputProps={{maxLength: 50}}
        required
      />
      <StringField
        setValue={handleChange("patronymic")}
        value={values.patronymic}
        name="patronymic"
        label="Отчество"
        errors={errors}
        setErrors={setErrors}
        disabled={loading || Boolean(values.id) || isClient === "1"}
        inputProps={{maxLength: 50}}
        required
      />
      <SelectSingleField
        setValue={handleChange("gender")}
        value={values.gender}
        name="gender"
        label="Пол"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        options={genderOptions}
      />
      <PickerDate
        setValue={handleChange("birth_date")}
        value={values.birth_date}
        label="Дата рождения"
        name="birth_date"
        maxDate={moment()
          .subtract(18, "years")
          .toDate()}
        disabled={loading}
      />
      <PhoneField
        setValue={handleChange("phone")}
        value={values.phone}
        name="phone"
        label="Контактный телефон (формат: 9279999999)"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 12}}
        required
      />
      <PhoneField
        setValue={handleChange("phone2")}
        value={values.phone2 || ""}
        name="phone2"
        label="Дополнительный телефон (формат: 9279999999)"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 12}}
      />
      <StringField
        setValue={handleChange("email")}
        value={values.email}
        name="email"
        label="Электронная почта"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 200}}
        required
      />
    </React.Fragment>
  );
};

export default PotentionalClientInputs;
