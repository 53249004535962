import React, {useState, Fragment} from "react";
import {StyledTabs, StyledTab} from "../../../../common/Tabs";
import {tabProps, TabPanel} from "../../../../common/components";
import {productType} from "../../../../../common/APIRequests/product/getProducts";
import ProductsList from "./ProductsList";
import BuyList from "./BuyList";

type Props = {
  products: Array<productType>;
  setProducts: Function;
  modeId: number | undefined;
  onProductDeselect: Function;
  onProductCountUpdate: Function;
};

const ProductTabs = (props: Props) => {
  const {
    products,
    modeId,
    setProducts,
    onProductCountUpdate,
    onProductDeselect,
  } = props;

  const defaultTab = "Products";

  const [tab, setTab] = useState(defaultTab);
  const handleTabChange = (e: React.ChangeEvent<{}>, tabValue: string) =>
    setTab(tabValue);

  const [tabsLoading, setTabsLoading] = useState(false);

  return (
    <Fragment>
      <StyledTabs
        value={tab}
        onChange={handleTabChange}
        aria-label="wrapped label tabs"
      >
        <StyledTab
          label="Продукты"
          {...tabProps("Products")}
          disabled={tabsLoading}
        />
        <StyledTab
          label={`Покупки ${products.length ? `(${products.length})` : ""}`}
          {...tabProps("Buy")}
          disabled={tabsLoading}
        />
      </StyledTabs>
      {tab === "Products" && (
        <TabPanel value={tab} index="Products">
          <ProductsList
            products={products}
            setProducts={setProducts}
            modeId={modeId}
            onProductDeselect={onProductDeselect}
          />
        </TabPanel>
      )}
      {tab === "Buy" && (
        <TabPanel value={tab} index="Buy">
          <BuyList
            selectedProducts={products}
            onProductDeselect={onProductDeselect}
            onProductCountUpdate={onProductCountUpdate}
          />
        </TabPanel>
      )}
    </Fragment>
  );
};

export default ProductTabs;
