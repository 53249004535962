import {useContext, useState} from "react";
import deletePrePotentionalClient from "../../../common/APIRequests/prePotentionalClient/deletePrePotentional";
import {prePotentionalClientType} from "../../../common/APIRequests/prePotentionalClient/getPrePotentionalClients";
import patchPrePotentionalClient from "../../../common/APIRequests/prePotentionalClient/patchPrePotentionalClient";
import {AppContext, AppContextType} from "../../App-Context";
import FormButtons from "../../common/buttons/FormButtons";
import {BigButton} from "../../common/buttons/StyledButton";
import PopupDialog from "../../common/dialog/PopupDialog";
import PhoneField from "../../common/formFields/PhoneField";
import StringField from "../../common/formFields/StringField";
import {alertType, errorType} from "../../common/types";
import PotentialClientForm, {
  defaultPotentialClient
} from "../AddPotentionalClientForm/PotentialClientForm";

type Props = {
  prePotentionalClient: prePotentionalClientType;
  close: () => void;
};

const PrePotentionalClientForm = (props: Props) => {
  const appContext: AppContextType = useContext(AppContext);
  const {setAlert, updatePrePotentionalCount} = appContext;

  const [loading, setLoading] = useState<boolean>(false);

  const [values, setValues] = useState<prePotentionalClientType>(
    props.prePotentionalClient
  );

  const [open, setOpen] = useState<boolean>(false);

  const [edited, setEdited] = useState<boolean>(false);

  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (value: string) => {
    setValues({...values, [name]: value});
    setEdited(true);
  };

  const handleSubmit = async () => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const result = await patchPrePotentionalClient(values.id, values);
      if (result.success) {
        setEdited(false);
        setAlert({
          severity: "success",
          message: ["Потенциальный контакт сохранен"]
        } as alertType);
      }
      setLoading(false);
    }
  };

  const handlePotentionalClientAddSuccess = async () => {
    await deletePrePotentionalClient(values.id);
    await updatePrePotentionalCount();
    props.close();
  };

  const potentionalClient = {...defaultPotentialClient, ...values};
  potentionalClient.id = 0;

  return (
    <>
      {open && (
        <PopupDialog
          onClose={() => setOpen(false)}
          title="Регистрация клиента"
          maxWidth="md"
        >
          <PotentialClientForm
            loading={loading}
            setLoading={setLoading}
            close={() => setOpen(false)}
            potentionalClient={potentionalClient}
            onSubmit={handlePotentionalClientAddSuccess}
          />
        </PopupDialog>
      )}
      <StringField
        setValue={handleChange("last_name")}
        value={values.last_name}
        name="last_name"
        label="Фамилия"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 50}}
        required
      />
      <StringField
        setValue={handleChange("first_name")}
        value={values.first_name}
        name="first_name"
        label="Имя"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 50}}
        required
      />
      <StringField
        setValue={handleChange("patronymic")}
        value={values.patronymic}
        name="patronymic"
        label="Отчество"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 50}}
        required
      />
      <PhoneField
        setValue={handleChange("phone")}
        value={values.phone}
        name="phone"
        label="Контактный телефон (формат: 9279999999)"
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        inputProps={{maxLength: 12}}
        required
      />
      <FormButtons
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={props.close}
        submitMessage="Сохранить"
        disabled={!edited}
      >
        {values.last_name && values.first_name && values.phone && !edited && (
          <BigButton
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Добавить в контакты
          </BigButton>
        )}
      </FormButtons>
    </>
  );
};

export default PrePotentionalClientForm;
