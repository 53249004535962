import {useContext} from "react";
import {AppContext} from "../App-Context";

const Home = () => {
  const appContext = useContext(AppContext);
  const {employee} = appContext;

  return (
    <>
      <h3>
        {employee
          ? `Добро пожаловать, ${employee.last_name} ${employee.first_name} ${employee.patronymic}`
          : undefined}
      </h3>
    </>
  );
};
export default Home;
