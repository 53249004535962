import {Button} from "@mui/material";
import {useContext, useState} from "react";
import getPotentionalClients, {
  PotentionalCLientFilterType,
  potentionalClientType
} from "../../common/APIRequests/potentionalClient/getPotentionalClients";
import {useTrigger} from "../../common/customHooks";
import {AppContext} from "../App-Context";
import Column from "../Tables/Column";
import CoreTable, {UpdateRowsType} from "../Tables/CoreTable";
import PopupDialog from "../common/dialog/PopupDialog";
import AddPotentionalClient from "./AddPotentionalClient";

const PotentionalClientColumns = [
  new Column("id", "ID", 100, {hiddenByDefault: true}),
  new Column("last_name", "Фамилия", 180),
  new Column("first_name", "Имя", 180),
  new Column("patronymic", "Отчество", 180),
  new Column("gender", "Пол", 100),
  new Column("birth_date", "Дата рождения", 140),
  new Column("phone", "Телефон 1", 140, {hiddenByDefault: true}),
  new Column("phone2", "Телефон 2", 140, {hiddenByDefault: true}),
  new Column("email", "Почта", 180, {hiddenByDefault: true}),
  new Column("add_date", "Дата добавления", 180, {hiddenByDefault: true})
];

const PotentionalClientTable = () => {
  const [refreshTrigger, refresh] = useTrigger();
  const {setAlert} = useContext(AppContext);

  const updateRows: UpdateRowsType<
    potentionalClientType,
    PotentionalCLientFilterType
  > = ({sorting, paging, filters}) =>
    getPotentionalClients(
      paging?.page,
      paging?.page_size,
      sorting?.sort_field,
      sorting?.sort_desc,
      filters
    );

  const [addOpen, setAddOpen] = useState<boolean>(false);

  return (
    <>
      {addOpen && (
        <PopupDialog
          onClose={() => setAddOpen(false)}
          title="Добавить контакт"
          maxWidth="sm"
        >
          <AddPotentionalClient close={() => setAddOpen(false)} />
        </PopupDialog>
      )}
      <CoreTable
        columns={PotentionalClientColumns}
        getRowId={row => row.id}
        name={"PotentionalClient"}
        updateRows={updateRows}
        updateTrigger={refreshTrigger}
        defaultSorting={{columnName: "last_name", direction: "asc"}}
        sortingEnabled
        pagingEnabled
        height={"48vh"}
        toolBarLeftItems={[
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => setAddOpen(true)}
          >
            Добавить
          </Button>
        ]}
      />
    </>
  );
};

export default PotentionalClientTable;
