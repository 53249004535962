import {Box, Container, Theme, Toolbar} from "@mui/material";
import React, {ReactNode} from "react";
import {makeStyles} from "tss-react/mui";
import {loginType} from "../common/APIRequests/login/postLogin";
import {useLocalStorage} from "../common/customHooks";
import getCurrentState from "../common/getCurrentState";
import LeomaxImage from "../common/png/leomax_big.png";
import LoginForm from "./authorization/LoginForm";
import LeftPanel from "./common/LeftPanel/LeftPanel";
import NoAccessedCurrentState from "./common/NoAccessedCurrentState";
import MenuPanel from "./common/topPanel/MenuPanel";

const useStyles = makeStyles()((theme: Theme) => ({
  appBarSpacer: {minHeight: 64},
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundImage: `linear-gradient(to bottom, rgba(226, 226, 226, 0.72), rgba(226, 226, 226, 0.93)), url(${LeomaxImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100vh",
    backgroundPosition: "bottom left"
  }
}));

export type ContentProps = {
  barContent?: ReactNode;
  Cont?: React.ComponentType;
  isAuth: boolean;
  userLogin: (login: loginType) => void;
  userLogout: () => void;
};

const Content = (props: ContentProps) => {
  const {barContent, Cont, isAuth, userLogin, userLogout} = props;

  const [open, setOpen] = useLocalStorage<boolean>("sidebarLeftVisible", true);

  const currentState = getCurrentState();

  const {classes} = useStyles();

  return (
    <>
      {isAuth && (
        <>
          <MenuPanel
            leftSidebarOpen={open}
            openLeftSidebar={setOpen}
            userLogout={userLogout}
          >
            {barContent}
          </MenuPanel>
          <LeftPanel open={open} handleClose={setOpen} />
        </>
      )}

      <Box component="main" className={classes.content}>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
          <Toolbar />
          {isAuth &&
          (currentState === "NOTENTERED" || currentState === "FIRED") ? (
            <NoAccessedCurrentState />
          ) : (
            isAuth && Cont && <Cont />
          )}
        </Container>
        {!isAuth && <LoginForm userLogin={userLogin} />}
      </Box>
    </>
  );
};

export default Content;
