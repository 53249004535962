import CloseIcon from "@mui/icons-material/Close";
import {DialogTitle, DialogTitleProps, IconButton} from "@mui/material";
import {makeStyles, withStyles} from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));

const StyledDialogTitle = withStyles(DialogTitle, theme => ({
  root: {
    paddingRight: 48
  }
}));

const ClosableDialogTitle = ({
  ...props
}: DialogTitleProps & {
  onClose: () => void;
  id?: string;
}) => {
  const {onClose, id} = props;
  const {classes} = useStyles();
  return (
    <StyledDialogTitle {...props}>
      {props.children}
      {onClose && (
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose && onClose()}
          id={id}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </StyledDialogTitle>
  );
};

export default ClosableDialogTitle;
