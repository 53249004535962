import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogActions,
  DialogContent,
  Paper
} from "@mui/material";
import {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
//@ts-ignore
import SignaturePad from "react-signature-canvas";
import {makeStyles} from "tss-react/mui";
import postEmailAttachment from "../../common/APIRequests/email/postEmailAttachment";
import {modeType} from "../../common/APIRequests/mode/getModes";
import {ownerType} from "../../common/APIRequests/owner/getOwners";
import patchOwner from "../../common/APIRequests/owner/patchOwner";
import postOwner from "../../common/APIRequests/owner/postOwner";
import {passportType} from "../../common/APIRequests/passport/getPassports";
import postPassport from "../../common/APIRequests/passport/postPassport";
import {potentionalClientType} from "../../common/APIRequests/potentionalClient/getPotentionalClients";
import postPotentionalClient from "../../common/APIRequests/potentionalClient/postPotentionalClient";
import postPrePotentionalClientAdd from "../../common/APIRequests/prePotentionalClient/postPrePotentionalClientAdd";
import {productType} from "../../common/APIRequests/product/getProducts";
import {productPurchaseType} from "../../common/APIRequests/productPurchase/getProductPurchases";
import postProductPurchases from "../../common/APIRequests/productPurchase/postProductPurchases";
import deletePurchase from "../../common/APIRequests/purchase/deletePurchase";
import generateAgreement from "../../common/APIRequests/purchase/generateAgreement";
import getPurchase from "../../common/APIRequests/purchase/getPurchase";
import {purchaseType} from "../../common/APIRequests/purchase/getPurchases";
import patchPurchaseMultipart from "../../common/APIRequests/purchase/patchPurchaseMultipart";
import postPurchase from "../../common/APIRequests/purchase/postPurchase";
import {AppContext} from "../App-Context";
import {defaultPotentialClient} from "../addPotentialClient/AddPotentionalClientForm/PotentialClientForm";
import ClientAgreement from "../addPotentialClient/ClientAgreement";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarSeparator
} from "../common/Toolbar";
import ButtonLoading from "../common/buttons/ButtonLoading";
import {StyledButton} from "../common/buttons/StyledButton";
import {Transition} from "../common/components";
import DraggableDialog from "../common/dialog/DraggableDialog";
import DraggableDialogTitle from "../common/dialog/DraggableDialogTitle";
import PopupDialog from "../common/dialog/PopupDialog";
import BooleanField from "../common/formFields/BooleanField";
import {useGlobalStyles} from "../common/styles";
import {alertType, errorType} from "../common/types";
import OwnerForm from "./Form/OwnerForm";
import PassportForm, {defaultPassport} from "./Form/PassportForm/PassportForm";
import NeedPotentionalClientNotification from "./Form/PrePotentinal/NeedPotentionalClientNotification";
import {PREPOTENTIONAL_CLIENT_COUNT} from "./Form/PrePotentinal/PrePotentional";
import PurchaseForm, {defaultPurchase} from "./Form/PurchaseForm/PurchaseForm";

export const urlToFile = async (
  url: string,
  fileName: string,
  mimeType: string
): Promise<File> =>
  new Promise(resolve =>
    fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => resolve(new File([buf], fileName, {type: mimeType})))
  );

const useStyles = makeStyles()((theme: any) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  card: {
    width: 450
    // height: 300
  }
}));

const AddAgreement = () => {
  const appContext = useContext(AppContext);
  const {
    employee,
    setAlert,
    updateEmployee,
    updatePrePotentionalCount
  } = appContext;

  const [selectedMode, setSelectedMode] = useState<modeType | undefined>(
    undefined
  );
  useEffect(() => {
    updateEmployee();
  }, []);

  const [client, setClient] = useState(
    defaultPotentialClient as potentionalClientType
  );

  const [passport, setPassport] = useState(defaultPassport as passportType);

  const [owner, setOwner] = useState({
    Purchase: 0,
    Employee: 0,
    Primary: false
  } as ownerType);

  const [recomendEmployee, setRecomendEmployee] = useState("0" as string);

  const [assistentEmployee, setAssistentEmployee] = useState("0" as string);

  const [products, setProducts] = useState([] as Array<productType>);

  const [purchase, setPurchase] = useState(defaultPurchase as purchaseType);

  const signature = useRef<SignaturePad>({});

  const [errors, setErrors] = useState({} as errorType);

  const [loading, setLoading] = useState(false as boolean);

  const [agree, setAgree] = useState(false as boolean);
  useEffect(() => {
    agree && setAgreementOpen(true);
  }, [agree]);

  const [agreementOpen, setAgreementOpen] = useState(false as boolean);

  const [successOpen, setSuccessOpen] = useState(false as boolean);

  const [modeNeedOpen, setModeNeedOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    if (agree && !signature.current.isEmpty()) {
      const {client, passport} = await handleSubmitPassport();

      if (client.id && passport.id) {
        let purchase: purchaseType = await handleSubmitPurchase(client);

        if (purchase && purchase.id) {
          await handleSignatureSave(purchase);

          await handleSubmitOwner(purchase);

          // generate agreement
          const generateResult = await generateAgreement(purchase.id);

          if (!generateResult.success) {
            setAlert({
              severity: "error",
              message: [
                "Во время создания договора произошла ошибка, обратитесь к разработчику"
              ]
            } as alertType);
            await deletePurchase(purchase.id);
          }

          if (generateResult.success && !Array.isArray(generateResult.rows)) {
            const result = await getPurchase(purchase.id);
            if (result.success && !Array.isArray(result.rows)) {
              purchase = result.rows;
              setPurchase(purchase);
            }

            // post prePotentionalClients
            if (purchase.id && selectedMode?.need_additional_clients) {
              const result = await postPrePotentionalClientAdd(
                PREPOTENTIONAL_CLIENT_COUNT,
                purchase.id
              );
              result.success && updatePrePotentionalCount();
            }

            //email to employee
            const owners = purchase.owner_set;
            if (owners && owners[0].Primary) {
              const mailEmployeeSubj =
                "Leomax International. Договор с клиентом";

              const ownerEmployee = owners[0].employee;

              if (ownerEmployee) {
                const mailEmployeeText = `${
                  ownerEmployee.gender === "M"
                    ? "Уважаемый господин,"
                    : "Уважаемая госпожа,"
                } ${ownerEmployee.last_name} ${ownerEmployee.first_name} ${
                  ownerEmployee.patronymic
                }!<br><br>Вами успешно заключен договор №${
                  purchase.id
                }.<br>Ваш клиент - ${client.last_name} ${client.first_name} ${
                  client.patronymic
                }.<br>Первая оплата: ${
                  purchase.payment_date
                }.<br><br>---<br>С уважением, служба технической поддержки Leomax International.`;

                const {agreement} = generateResult.rows;

                if (agreement) {
                  const postEmailResult = await postEmailAttachment({
                    subj: mailEmployeeSubj,
                    text: mailEmployeeText,
                    recipients: [ownerEmployee.email, "ecopom@ecopom.com"],
                    file_link: agreement,
                    file_name: "agreement.pdf"
                  });
                }

                // email to client
                const mailClientSubj =
                  "Leeomax International. Договор с компанией.";

                const mailClientText = `${
                  client.gender === "M"
                    ? "Уважаемый господин,"
                    : "Уважаемая госпожа,"
                } ${client.last_name} ${client.first_name} ${
                  client.patronymic
                }!<br><br>Поздравляем Вас с удачным выбором по договору №${
                  purchase.id
                } от ${purchase.date}.<br>Ваш Advisor - ${
                  ownerEmployee.last_name
                } ${ownerEmployee.first_name} ${
                  ownerEmployee.patronymic
                }.<br>Контактный телефон: +${
                  ownerEmployee.phone
                }.<br><br>---<br>С уважением, служба технической поддержки Leomax International.`;

                agreement
                  ? (async () => {
                      const postEmailResult = await postEmailAttachment({
                        subj: mailClientSubj,
                        text: mailClientText,
                        recipients: [client.email, "ecopom@ecopom.com"],
                        file_link: agreement,
                        file_name: "agreement.pdf"
                      });
                    })()
                  : undefined;
              }
            }
            setSuccessOpen(true);
            setAlert({
              severity: "success",
              message: [
                "Договор успешно сформирован, Вам и клиенту направлены письма с договорами"
              ]
            } as alertType);
          }
        }
      } else {
        setAlert({
          severity: "error",
          message: "В данных клиента содержатся ошибки"
        });
      }
    } else {
      setErrors({...errors, signature: ["Необходимо поставить подпись"]});
      setAlert({
        severity: "error",
        message: ["Отсутствует подпись"]
      } as alertType);
    }
    setLoading(false);
  };

  const handleSubmitPassport = async () => {
    setErrors({});

    let newErrors = {};

    // client
    const {id: clientId} = client;

    let newClient = client;
    let newPassport = passport;
    if (!clientId) {
      const result = await postPotentionalClient(client);

      if (result.success && !Array.isArray(result.row) && result.row) {
        setClient(result.row);
        client.id = result.row.id;
        newClient = result.row;
      } else {
        newErrors = {...newErrors, ...result.errors};
      }
    }

    // passport
    const {id: passportId} = passport;
    if (!passportId && client.id) {
      if (!passport.PotentionalClient && client.id) {
        passport.PotentionalClient = client.id;
      }
      const result = await postPassport(passport);

      if (result.success && !Array.isArray(result.row) && result.row) {
        setAlert({
          severity: "success",
          message: ["Данные клиента успешно внесены"]
        } as alertType);
        setPassport(result.row);
        newPassport = result.row;
      } else {
        newErrors = {...newErrors, ...result.errors};
      }
    }
    Object.keys(newErrors).length &&
      setAlert({
        severity: "error",
        message: ['Ошибки в полях формы "Данные клиента"']
      } as alertType);
    setErrors(newErrors);

    return {
      client: newClient,
      passport: newPassport
    };
  };

  const handleSubmitOwner = async (purchase: purchaseType) => {
    if (recomendEmployee === "1" || assistentEmployee === "1") {
      if (purchase.id) {
        const result = await getPurchase(purchase.id);
        if (!Array.isArray(result.rows)) {
          //@ts-ignore
          const owners = result.rows.owner_set;

          if (Array.isArray(owners)) {
            setErrors({});
            // modify last
            if (owners[0].id && employee && employee.id) {
              if (owner.Employee !== employee.id) {
                const resultPatch = await patchOwner(owners[0].id, {
                  Purchase: purchase.id,
                  Employee: owner.Employee,
                  Primary: true
                } as ownerType);
                // creates new
                const resultPost = await postOwner({
                  Purchase: purchase.id,
                  Employee: employee.id,
                  Primary: false
                } as ownerType);

                resultPatch.errors && setErrors(resultPatch.errors);
                resultPost.errors && setErrors(resultPost.errors);
              }
            }
          }
        }
      }
    }
  };

  const handleSubmitPurchase = async (
    potentionalClient: potentionalClientType
  ) => {
    setErrors({});
    let createdPurchase = purchase as purchaseType;
    if (products.length) {
      const result = await postPurchase({
        ...purchase,
        PotentionalClient: potentionalClient.id
      });

      if (result.success && !Array.isArray(result.row) && result.row) {
        purchase.id = result.row.id;
        createdPurchase = purchase;
        // setPurchase({...purchase, id: purchase.id});
        const productPurchases = products.map((product: productType) => {
          const {id: productId, count, rur} = product;
          let result = {} as productPurchaseType;
          if (productId && count && rur) {
            purchase.id &&
              (result = {
                id: undefined,
                Product: productId,
                Purchase: purchase.id,
                count: count,
                rur: rur
              });
          }
          return result;
        });
        const productPurchaseResult = await postProductPurchases(
          productPurchases
        );
        if (productPurchaseResult.success) {
          setProducts([]);
        }
      } else {
        setErrors(result.errors);
        setAlert({severity: "error", message: "В форме заказа имеются ошибки"});
      }
    } else {
      setAlert({
        severity: "error",
        message: ["Отстутствуют выбранные продукты"]
      } as alertType);
    }
    setPurchase({...purchase, PotentionalClient: potentionalClient.id});
    return createdPurchase;
  };

  const handleSignatureSave = async (purchase: purchaseType) => {
    if (signature.current) {
      const fileUrl = signature.current.toDataURL();

      const formData = new FormData();

      const file = await urlToFile(fileUrl, "signature.png", "image/png");

      formData.append("signature", file);

      if (purchase.id) {
        const patchPurchaseMultipartResult = await patchPurchaseMultipart(
          formData,
          purchase.id
        );
      }
    }
  };

  const history = useHistory();

  const {classes: localClasses} = useStyles();
  const classes = useGlobalStyles();

  return (
    <Fragment>
      <main className={localClasses.layout}>
        <Paper className={localClasses.paper}>
          <NeedPotentionalClientNotification mode={selectedMode} />
          {agreementOpen && (
            <PopupDialog
              onClose={() => setAgreementOpen(false)}
              title="Согласие на обработку персональных данных"
              maxWidth="lg"
            >
              <DialogContent>
                <ClientAgreement />
                <StyledButton onClick={() => setAgreementOpen(false)}>
                  Закрыть
                </StyledButton>
              </DialogContent>
            </PopupDialog>
          )}
          {successOpen && (
            <DraggableDialog
              open
              keepMounted
              TransitionComponent={Transition}
              maxWidth="lg"
              aria-labelledby="success-modal-open"
            >
              <DraggableDialogTitle id="success-modal-open">
                Поздравляем Вас с успешным приобретением продукции Leomax
                International!
              </DraggableDialogTitle>
              <DialogContent>
                <a href={`${purchase.agreement}`} download target="_blank">
                  Скачать договор
                </a>
              </DialogContent>
              <DialogActions>
                <StyledButton onClick={() => history.push("/")}>
                  Продолжить
                </StyledButton>
              </DialogActions>
            </DraggableDialog>
          )}
          <OwnerForm
            employee={employee}
            owner={owner}
            setOwner={setOwner}
            recomendEmployee={recomendEmployee}
            setRecomendEmployee={setRecomendEmployee}
            assistentEmployee={assistentEmployee}
            setAssistentEmployee={setAssistentEmployee}
          />
          <PassportForm
            client={client}
            setClient={setClient}
            passport={passport}
            setPassport={setPassport}
            setErrors={setErrors}
            errors={errors}
          />
          <PurchaseForm
            passport_address={passport.passport_address}
            products={products}
            setProducts={setProducts}
            purchase={purchase}
            setPurchase={setPurchase}
            errors={errors}
            setErrors={setErrors}
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
          <BooleanField
            name="agree"
            label="Согласен на обработку персональных данных"
            setValue={() => {
              setAgree(!agree);
              setAgreementOpen(!agree);
            }}
            value={agree ? agree.toString() : "0"}
            errors={errors}
            setErrors={setErrors}
            disabled={loading}
            required={true}
          />
          <Card className={localClasses.card}>
            <CardHeader
              title="Подпись *"
              titleTypographyProps={{
                color: errors.signature ? "error" : "textSecondary",
                variant: "body2"
              }}
            />
            <CardContent>
              <SignaturePad
                ref={signature}
                canvasProps={{height: 300, width: 450}}
                penColor="rgb(66,133,244)"
              />
            </CardContent>
            <CardActions>
              <StyledButton
                onClick={() => signature && signature.current.clear()}
                fullWidth
                variant="text"
                color="primary"
              >
                Очистить
              </StyledButton>
            </CardActions>
          </Card>

          <BottomToolbarWrapper>
            <ToolbarItem>
              <ButtonLoading
                fullWidth
                color="primary"
                loading={loading}
                submit={() => {
                  !agree
                    ? setErrors({
                        ...errors,
                        agree: [
                          "Необходимо поставить отметку Согласен на обработку персональных данных"
                        ]
                      })
                    : handleSubmit();
                }}
              >
                Сформировать
              </ButtonLoading>
            </ToolbarItem>
            <ToolbarSeparator />
          </BottomToolbarWrapper>
          {loading && <CircularProgress className={classes.tableProgress} />}
        </Paper>
      </main>
    </Fragment>
  );
};

export default AddAgreement;
