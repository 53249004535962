import {Proxy} from "../../../settings";
import {basePatchRequest} from "../base/basePatchRequest";
import {prePotentionalClientType} from "./getPrePotentionalClients";

const patchPrePotentionalClient = async (
  prePotentionalClientId: number,
  data: prePotentionalClientType
) =>
  basePatchRequest<prePotentionalClientType>(
    `${Proxy}/pre_potentional_client/${prePotentionalClientId}/`,
    data
  );

export default patchPrePotentionalClient;
