import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography
} from "@mui/material";
import React from "react";
import theme from "../../../theme";
import {fieldProps} from "../types";

const BooleanField = (props: fieldProps) => {
  const {
    setValue,
    value,
    name,
    label,
    style,
    disabled,
    errors,
    required
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete errors[name];
    setValue(e.target.checked === true ? "1" : "0");
  };

  return (
    <FormControl
      fullWidth
      style={style ? style : {marginBottom: theme.spacing(2)}}
      error={!!errors[name]}
    >
      <FormLabel component="legend" required={required}>
        <Typography variant="caption" display="block">
          {label}
        </Typography>
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={value === "1" || value === "true"}
            id={name}
            name={name}
            onChange={handleChange}
            disabled={disabled || false}
          />
        }
        label={undefined}
      />
      <FormHelperText>{errors[name]}</FormHelperText>
    </FormControl>
  );
};

export default BooleanField;
