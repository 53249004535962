import {baseGetRequest} from "../base/baseGetRequest";

export type prePotentionalClientType = {
  id: number;
  last_name: string;
  first_name: string;
  patronymic: string;
  phone: string;
  Employee?: number;
  potentonal_client?: string;
};

const getPrePotentionalClients = async () =>
  baseGetRequest<prePotentionalClientType>(
    `/pre_potentional_client/`,
    undefined
  );

export default getPrePotentionalClients;
