import {Sorting} from "@devexpress/dx-react-grid";
import React, {ReactText} from "react";
import Column from "./Column";

export type TableContextType<RowType, FilterType = undefined> = {
  selection: ReactText[];
  sorting?: Sorting[];
  selectedRows: RowType[];
  filters: FilterType;
  loading: boolean;
  currentPage?: number;
  totalCount?: number;
  rows: RowType[];
  columns: Column[];
  hiddenColumnNames: string[];
  updateRows?: () => void;
};

const tableContextDefault: TableContextType<unknown, unknown> = {
  selection: [],
  selectedRows: [],
  filters: {},
  loading: false,
  currentPage: 0,
  rows: [],
  columns: [],
  hiddenColumnNames: [],
  updateRows: undefined,
};

const TableContext = React.createContext(tableContextDefault);

export default TableContext;
