import {TableColumnWidthInfo} from "@devexpress/dx-react-grid";
import {useEffect, useRef, useState} from "react";

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
};

export function useLocalStorage<T>(
  key: string | undefined,
  initialValue: T,
  clean = false
) {
  key && clean && window.localStorage.removeItem(key);
  const [storedValue, setStoredValue] = useState(() => {
    if (!key) return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      const value: T = item ? JSON.parse(item) : initialValue;
      return value;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      key && window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as [T, (value: T) => void];
}

export function useLocalStorageCategory<T>(
  key: string,
  categoryKey: string,
  initialValue: T
) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const category = window.localStorage.getItem(categoryKey);
      const categoryValue: {[key: string]: T} = category
        ? JSON.parse(category)
        : {};
      return categoryValue[key] !== undefined
        ? categoryValue[key]
        : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      const category = window.localStorage.getItem(categoryKey);
      const categoryValue: {[key: string]: T} = category
        ? JSON.parse(category)
        : {};
      categoryValue[key] = value;
      window.localStorage.setItem(categoryKey, JSON.stringify(categoryValue));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as [T, (value: T) => void];
}


/**
 * Gets or creates coloumns order stored in browser localStorage. Prevents table columns migration bug
 * @param key name in localStorage
 * @param initialValue value by default
 */
export const useLocalStorageColumnOrder = (
  key: string,
  initialValue: Array<string>
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      let value: Array<string>;
      if (item) {
        const localStorageOrder = JSON.parse(item);
        // Below fix to prevents table columns migration bug
        const hasInInitial = localStorageOrder.filter((columnName: string) =>
          initialValue.includes(columnName)
        );
        const notInLocalStorage = initialValue.filter(
          (columnName: string) => !localStorageOrder.includes(columnName)
        );
        value = hasInInitial.concat(notInLocalStorage);
        // actualize localStorage
        localStorageOrder !== value &&
          window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        value = initialValue;
      }
      return value;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: Array<string>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as [
    Array<string>,
    (value: Array<string>) => void
  ];
};

export function useLocalStorageColumnWidths(
  key: string | undefined,
  columnWidths: TableColumnWidthInfo[]
) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (!key) return columnWidths;
      // if localStorage filter values not in condition fix
      const item = window.localStorage.getItem(key);
      const oldColumnWidths = item ? JSON.parse(item) : [];
      const oldKeys = oldColumnWidths.map((cE: any) => cE.columnName);
      const clear =
        oldKeys.join() !== columnWidths.map(c => c.columnName).join();

      if (clear) {
        window.localStorage.removeItem(key);
      } else {
        // search for "auto" width value and replace it by new number value
        return oldColumnWidths.map((column: any) => {
          if (column.width === "auto" || column.width === null) {
            column.width = columnWidths.find(
              ext => ext.columnName === column.columnName
            )!.width;
          }
          return column;
        });
      }
    } catch (error) {
      console.error(error);
    }
    return columnWidths;
  });

  const setValue = (value: Array<TableColumnWidthInfo>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      key && window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as [
    Array<TableColumnWidthInfo>,
    (value: Array<TableColumnWidthInfo>) => void
  ];
}

export function useDebouncedEffect(
  callback: Function,
  delay: number,
  deps: any[] = []
) {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
}

export function useTrigger() {
  const [trigger, setTrigger] = useState(false);
  const updateTrigger = () => setTrigger((prevTrigger) => !prevTrigger);
  return [trigger, updateTrigger] as [boolean, () => void];
}

export function useEffectOnlyOnUpdate(
  callback: Function,
  dependencies: Array<any>
) {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, dependencies);
}
