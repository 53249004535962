import {Dialog, DialogContent} from "@mui/material";
import * as React from "react";
import {Transition} from "../components";
import ClosableDialogTitle from "./ClosableDialogTitle";

type PopupDialogProps = {
  onClose: () => void;
  title: string;
  children: React.ReactElement;
  fullWidth?: boolean;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
};
const PopupDialog = (props: PopupDialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      keepMounted
      TransitionComponent={Transition}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
    >
      <ClosableDialogTitle onClose={props.onClose}>
        {props.title}
      </ClosableDialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

export default PopupDialog;
