import ReactDOM from "react-dom";

//material ui
import theme from "./theme";

//styles
import "rc-tooltip/assets/bootstrap_white.css";
import "./common/scss/common.scss";

import {ThemeProvider} from "@mui/material/styles";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById("root")
  );
};

window.onload = () => render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
