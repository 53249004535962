import {red} from "@mui/material/colors";
import {createUseStyles} from "react-jss";
import theme from "../../theme";

export const useGlobalStyles = createUseStyles(() => ({
  // style color tablegrid row
  rowColorRed: {
    color: `${red.A700}!important`
  },
  // loaders
  tableProgress: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 1
  },
  actionGrid: {
    padding: 0
  },

  // table grid
  gridRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
  },

  gridRowSelected: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  },
  // expansion panel
  panelSummary: {
    minHeight: "44px!important"
  },
  panelSummaryContent: {
    margin: "10px 0!important"
  },
  panelDetail: {
    borderTop: "2px solid #eee"
  },
  chart: {
    height: "30vh!important"
  },

  topMargin: {
    marginTop: theme.spacing(2)
  }
}));
