import {
  TableHeaderRow,
  TableHeaderRowProps
} from "@devexpress/dx-react-grid-material-ui";
import React, {useEffect, useRef} from "react";
import {StyledTableHeaderCell} from "./cell";
import {TableHeaderRow as TableHeaderRowBase} from "@devexpress/dx-react-grid";

export type minWidthSetter = (params: {
  columnName: string;
  minWidth: number;
}) => void;

type TableHeaderCellWidthControlProps = {
  addMinWidth?: minWidthSetter;
} & TableHeaderRowBase.CellProps;

const headerCellStyle = {
  horizontalPadding: 8
};

const TableHeaderCellWidthControl = (
  props: TableHeaderCellWidthControlProps
) => {
  const {children, addMinWidth, ...cellProps} = props;
  const cellRef = useRef(null);

  useEffect(() => {
    const current: any = cellRef.current;
    if (current && current.clientWidth && addMinWidth) {
      addMinWidth({
        columnName: props.column.name,
        minWidth: current.clientWidth + 2 * headerCellStyle.horizontalPadding
      });
    }
  }, []);

  return (
    <StyledTableHeaderCell {...cellProps}>
      <div ref={cellRef}>{children}</div>
    </StyledTableHeaderCell>
  );
};

export const TableHeaderRowWidthControl = (
  props: TableHeaderRowProps & {addMinWidth?: minWidthSetter}
) => {
  return (
    <TableHeaderRow
      {...props}
      cellComponent={(cellProps: TableHeaderCellWidthControlProps) => (
        <TableHeaderCellWidthControl
          {...cellProps}
          addMinWidth={props.addMinWidth}
        />
      )}
    />
  );
};

export default TableHeaderRowWidthControl;
