import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, {Dayjs} from "dayjs";
import moment from "moment";
import {useEffect, useState} from "react";
import {BACKEND_DATE_FORMAT} from "../../../common/types";

type PickerDateProps = {
  setValue: (value: string | null) => void;
  onError?: () => void;
  value: string | null;
  name: string;
  label: string;
  disabled: boolean;
  maxDate?: Date;
};

const PickerDate = (props: PickerDateProps) => {
  const [tempValue, setTempValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    setTempValue(dayjs(props.value, BACKEND_DATE_FORMAT));
  }, [props.value]);

  const submitValue = (date: Dayjs) => {
    const formattedDate = date && date.format(BACKEND_DATE_FORMAT);
    props.setValue(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={props.label}
        value={tempValue}
        onChange={(date: any) =>
          props.setValue(
            moment(date).format(BACKEND_DATE_FORMAT) ? date.toString() : null
          )
        }
        onError={(reason: any, date: any) => {
          if (!reason) {
            submitValue(date);
          } else {
            props.onError?.();
          }
        }}
        onAccept={(date: Dayjs) => {
          submitValue(date);
        }}
        format="DD.MM.YYYY"
        disabled={props.disabled}
        maxDate={dayjs(props.maxDate)}
      />
    </LocalizationProvider>
  );
};

export default PickerDate;
