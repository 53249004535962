import React, {Fragment} from "react";

const AgreementText = () => (
  <Fragment>
    <p>
      Согласие даётся мной для целей исполнения Агентского договора, принятия решений или совершения иных действий,
      порождающих юридические последствия в отношении меня или других лиц, предоставления мне информации о реализуемых
      Компанией товарах, оказываемых услугах, организуемых маркетинговых мероприятиях, предложений и каталогов в форме
      писем, sms и электронных сообщений.
    </p>
    <p>
      Согласие распространяется на следующую информацию: мои фамилия, имя, отчество, год, месяц, дата и место рождения,
      адрес, образование, профессия, доходы, и любая иная информация, относящаяся к моей личности, доступная либо
      известная в любой конкретный момент времени Компании (далее – «Персональные данные»).
    </p>
    <p>
      Настоящее согласие даётся до истечения сроков хранения соответствующей информации или документов, содержащих
      вышеуказанную информацию, определяемых в соответствии с законодательством РФ, после чего может быть отозвано путём
      направления мной соответствующего письменного уведомления не менее чем за 3 (три) месяца до момента отзыва
      согласия.
    </p>
    <p>
      Настоящее согласие предоставляется на осуществление любых действий в отношении моих персональных данных, которые
      необходимы или желаемы для достижения указанных выше целей, включая, без ограничения: сбор, систематизацию,
      накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передача),
      обезличивание, блокирование, уничтожение, трансграничную передачу Персональных данных, а также осуществления любых
      иных действий с моими Персональными данными с учётом действующего законодательства.
    </p>
    <p>
      Обработка Персональных данных осуществляется с применением следующих основных способов (но, не ограничиваясь ими):
      хранение, запись на электронные носители и их хранение, составление перечней, маркировка.
    </p>
    <p>
      Настоящим я признаю и подтверждаю, что в случае необходимости предоставления Персональных данных для достижения
      указанных выше целей третьему лицу, а равно как при привлечении третьих лиц к оказанию услуг в указанных целях,
      передачи Компанией принадлежащих ему функций и полномочий иному лицу, Компания вправе в необходимом объёме
      раскрывать для совершения вышеуказанных действий информацию обо мне лично (включая мои Персональные данные) таким
      третьим лицам, а также предоставлять таким лицам соответствующие документы, содержащие такую информацию. Также
      настоящим признаю и подтверждаю, что настоящие согласие считается данным мною любым третьим лицам, указанным выше,
      с учётом соответствующих изменений, и любые такие третьи лица имеют право на взаимодействие со мной и обработку
      Персональных данных на основании настоящего согласия.
    </p>
  </Fragment>
);

export default AgreementText;
