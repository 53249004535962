/**
 * @description if user authorized gets token from localstorage to access to backend
 * @function
 * @returns {string|undefined} token hash or undefined
 */
const getToken: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('token' in auth) {
      return auth.token.access
    }
  }
}

export default getToken
