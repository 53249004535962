import {CircularProgress, Paper} from "@mui/material";
import {Fragment, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useGlobalStyles} from "../common/styles";
import EmployeeForm from "./EmployeeForm";

const useStyles = makeStyles()((theme: any) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

const AddEmployee = () => {
  const [loading, setLoading] = useState(false);

  const {classes: localClasses} = useStyles();
  const classes = useGlobalStyles();

  return (
    <Fragment>
      <main className={localClasses.layout}>
        <Paper className={localClasses.paper}>
          <EmployeeForm
            employeeId={undefined}
            setLoading={setLoading}
            loading={loading}
          />
          {loading && <CircularProgress className={classes.tableProgress} />}
        </Paper>
      </main>
    </Fragment>
  );
};

export default AddEmployee;
