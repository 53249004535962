import {Toolbar} from "@devexpress/dx-react-grid-material-ui";
import {Box, Theme} from "@mui/material";
import {withStyles} from "@mui/styles";
import {makeStyles} from "tss-react/mui";
import theme from "../../theme";

export const ToolbarItem = withStyles({
  root: {
    marginRight: theme.spacing(2)
  }
})(Box);

export const ToolbarSeparator = (props: any) => {
  return (
    <div
      style={{
        flex: "0 0 0px",
        marginLeft: "auto"
      }}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  toolbarWrapper: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    alignItems: "flex-end",
    minHeight: "64px"
  }
}));

export const ToolbarWrapper = (props: {children: any}) => {
  const {children} = props;

  const {classes} = useStyles();

  return <Box className={classes.toolbarWrapper}>{children}</Box>;
};

export const BottomToolbarWrapper = (props: {children: any}) => {
  const {children} = props;

  const useStyles = makeStyles()(theme => ({
    toolbarWrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }));

  const {classes} = useStyles();

  return <Box className={classes.toolbarWrapper}>{children}</Box>;
};

export const ToolbarRoot = (props: Toolbar.RootProps) => {
  return (
    <Toolbar.Root
      {...props}
      style={{
        alignItems: "flex-end",
        minHeight: 0,
        paddingBottom: "10px",
        paddingTop: "10px",
        paddingLeft: 0,
        paddingRight: 0
      }}
    />
  );
};
