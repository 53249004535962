import {DialogActions, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {modeType} from "../../../../common/APIRequests/mode/getModes";
import {StyledButton} from "../../../common/buttons/StyledButton";
import PopupDialog from "../../../common/dialog/PopupDialog";

type Props = {
  mode: modeType | undefined;
};
const NeedPotentionalClientNotification = (props: Props) => {
  const {mode} = props;
  useEffect(() => {
    if (mode && mode.need_additional_clients && !open) {
      setOpen(true);
    }
  }, [mode && mode.need_additional_clients]);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {mode && mode.need_additional_clients && open && (
        <PopupDialog
          onClose={() => setOpen(false)}
          title="Внимание!"
          maxWidth="sm"
        >
          <>
            <DialogContent>
              Вид продаж требует после зключения договора заполнить
              потенциальных клиентов
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={() => setOpen(false)}>
                Закрыть
              </StyledButton>
            </DialogActions>
          </>
        </PopupDialog>
      )}
    </>
  );
};

export default NeedPotentionalClientNotification;
