import React, {useState} from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Backdrop,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Modal
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import deleteLogout from "../../../common/APIRequests/login/deleteLogout";
import getSessionKey from "../../../common/getSessionKey";
import getUsername from "../../../common/getUsername";
import getLoginInfo from "../../../common/utils/getLoginInfo";
import {appVersion, buildNumber} from "../../../settings";

const useStyles = makeStyles()((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const LoginMenu = (props: {userLogout: Function}) => {
  const {userLogout} = props;

  const [aboutProductOpen, setAboutProductOpen] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElHelp, setAnchorElHelp] = useState(null);

  const handleLogout = async (
    e: React.SyntheticEvent<HTMLElement, Event>
  ): Promise<void> => {
    await deleteLogout(getSessionKey());
    userLogout();
  };

  const {classes} = useStyles();

  return (
    <React.Fragment>
      {getLoginInfo() && getUsername() && (
        <Button
          aria-label="Пользователь"
          aria-controls="profile-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={(e: any) => setAnchorElUser(e.currentTarget)}
        >
          {getUsername()}
        </Button>
      )}
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        id="profile-menu"
        keepMounted
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={Boolean(anchorElUser)}
        onClose={() => {
          setAnchorElUser(null);
        }}
        elevation={0}
      >
        <MenuItem onClick={handleLogout}>Выйти</MenuItem>
      </Menu>
      <IconButton
        aria-label="Помощь"
        aria-controls="profile-help"
        aria-haspopup="true"
        color="inherit"
        onClick={(e: any) => setAnchorElHelp(e.currentTarget)}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElHelp}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        id="profile-help"
        keepMounted
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={Boolean(anchorElHelp)}
        onClose={() => {
          setAnchorElHelp(null);
        }}
        elevation={0}
      >
        <MenuItem onClick={() => setAboutProductOpen(true)}>
          Версия приложения
        </MenuItem>
      </Menu>

      <Modal
        className={classes.modal}
        open={aboutProductOpen}
        onClose={() => setAboutProductOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={aboutProductOpen}>
          <div className={classes.paper}>
            Версия: {appVersion}_{buildNumber}
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default LoginMenu;
