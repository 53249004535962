import { errorType, columnType } from "./types";

export const alertOrError = (
  setAlert: Function,
  errors: errorType,
  setErrors?: Function
) =>
  errors.non_field_errors
    ? setAlert({
      severity: "error",
      message: Array.isArray(errors.non_field_errors)
        ? errors.non_field_errors[0]
        : errors.non_field_errors
    })
    : setErrors && setErrors(errors);

export function getHiddenColumns(columns: columnType) {
  let result: Array<string> = [];
  columns.forEach(function (item, i) {
    item.hidden && result.push(item.name);
  });
  return result;
}

export function getDefaultColumnWidths(
  columns: columnType,
  defaultWidth = "200"
) {
  let result: Array<{ columnName: string; width: string }> = [];
  columns.forEach((item, i) => {
    result.push({
      columnName: item.name,
      width: item.width ? item.width : defaultWidth
    });
  });
  return result;
}

export function getSortingState(columns: columnType) {
  let result: Array<{ columnName: string; sortingEnabled: boolean }> = [];
  columns.forEach(function (item, i) {
    item.sortingEnabled === false &&
      result.push({
        columnName: item.name,
        sortingEnabled: false
      });
  });
  return result;
}

export const getExtension = (filename: string) => {
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

export const downloadFile = (data: any, fileName: string) => {
  let file = new Blob([data], { type: "text/plain;charset=utf-8" }),
    a = document.createElement("a"),
    url = URL.createObjectURL(file);

  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

/**
 * @description first grouped=groupBy(pets, pet => pet.type) when grouped.get('Dog')
 */
export function groupBy<T>(list: Array<T>, keyGetter: Function) {
  const map = new Map();
  list.forEach((item: T) => {
    const key = keyGetter(item);
    let collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}