import {Proxy} from "../../../settings";
import {basePostRequest} from "../base/basePostRequest";

export type emailAttachmentType = {
  subj: string;
  text: string;
  recipients: Array<string>;
  file_link: string;
  file_name: string;
};

const postEmailAttachment = async (data: emailAttachmentType) =>
  basePostRequest<emailAttachmentType>(`${Proxy}/send_mail_attachment/`, data);

export default postEmailAttachment;
