/**
 * @description return current_state  of current user or false
 * @returns {string|undefined} role
 */
const getCurrentState: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('current_state' in auth) {
      return auth.current_state
    }
  }
}

export default getCurrentState
