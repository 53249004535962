import {Typography} from "@mui/material";
import {Fragment} from "react";

const NoAccessedCurrentState = () => {
  return (
    <Fragment>
      <Typography>Нет разрешения для просмотра страницы</Typography>
      <Typography>
        Обратитесь к администратору или в службу поддержки
      </Typography>
    </Fragment>
  );
};
export default NoAccessedCurrentState;
