import React, {Fragment} from "react";
import {structureType} from "../../common/APIRequests/getStructure/getStructure";
import {columnType} from "../common/types";
import {getHiddenColumns} from "../common/functions";
import {SearchState, IntegratedFiltering} from "@devexpress/dx-react-grid";
import {
  SearchPanel,
  Table,
  Grid,
  TableColumnVisibility,
  TableColumnResizing,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import StyledColumnChooser from "../common/grid/StyledColumnChooser";
import {StyledTableHeaderRow} from "../common/grid/cell";
import {ToolbarRoot} from "../common/Toolbar";

const columns: columnType = [
  {
    name: "id",
    title: "id",
    getCellValue: (row: structureType) => row.employee.id,
  },
  {
    name: "name",
    title: "ФИО",
    getCellValue: (row: structureType) =>
      `${row.employee.last_name} ${row.employee.first_name} ${row.employee.patronymic}`,
  },
  {name: "status", title: "Статус"},
  {
    name: "level",
    title: "Ln",
    getCellValue: (row: structureType) => row.employee.level,
  },
  {name: "owner_signed", title: "ЛО (закл.)"},
  {name: "owner_paid_of_signed", title: "ЛО (реал.)"},
  {name: "structure_signed", title: "ОС (закл.)"},
  {name: "structure_paid_of_signed", title: "ОС (реал.)"},
  {name: "rank", title: "Ранг"},
  {name: "operation_commissions", title: "OK"},
  {name: "business_percent", title: "БК %"},
  {name: "business_commissions", title: "БК"},
];

const columnExtensions = [
  {columnName: "id", width: 50, minWidth: 10},
  {columnName: "name", width: 200, minWidth: 70},
  {columnName: "status", width: 110, minWidth: 70},
  {columnName: "level", width: 70, minWidth: 70},
  {columnName: "owner_signed", width: 110, minWidth: 80},
  {columnName: "owner_paid_of_signed", width: 110, minWidth: 80},
  {columnName: "structure_signed", width: 110, minWidth: 80},
  {columnName: "structure_paid_of_signed", width: 110, minWidth: 80},
  {columnName: "rank", width: 110, minWidth: 80},
  {columnName: "operation_commissions", width: 110, minWidth: 80},
  {columnName: "business_percent", width: 110, minWidth: 80},
  {columnName: "business_commissions", width: "auto", minWidth: 80},
];

type Props = {
  structure: Array<structureType>;
};

const StructureTableNew = (props: Props) => {
  const {structure} = props;

  const hiddenColumnName = getHiddenColumns(columns);

  return (
    <Fragment>
      <Grid columns={columns} rows={structure} getRowId={(row: any) => row.id}>
        <Table messages={{noData: "Нет данных"}} />

        {/*Toolbar*/}
        <Toolbar rootComponent={ToolbarRoot} />

        {/*Column Visibility*/}
        <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnName} />
        <StyledColumnChooser />

        {/*Column Resizing*/}
        <TableColumnResizing
          defaultColumnWidths={columnExtensions}
          resizingMode="nextColumn"
          columnExtensions={columnExtensions}
        />

        <StyledTableHeaderRow />
      </Grid>
    </Fragment>
  );
};

export default StructureTableNew;
