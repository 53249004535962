import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {Divider, IconButton, List, Toolbar} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {styled} from "@mui/material/styles";
import getRole from "../../../common/getRole";
import {LeftPanelGroup} from "./LeftPanelGroup";
import {LeftPanelGroupListItem} from "./LeftPanelItems";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== "open"})(
  ({theme, open}) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9)
        }
      })
    }
  })
);

const LeftPanel = (props: {
  open: boolean;
  handleClose: (value: boolean) => void;
}) => {
  const {open, handleClose} = props;

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1]
        }}
      >
        <IconButton onClick={() => handleClose(!open)}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <LeftPanelGroupListItem
          url="/"
          name="Home"
          title="Главная"
          icon={HomeIcon}
          leftPanelOpen={open}
        />
        <LeftPanelGroup
          leftPanelOpen={open}
          title="Продажа"
          icon={PeopleIcon}
          links={[
            {
              url: "/potentional_client_add/",
              name: "Contact",
              title: "Контакт"
            },
            {
              url: "/purchase_add/",
              name: "Agreement",
              title: "Договор с клиентом"
            }
          ]}
        />
        {getRole() !== "EMPLOYEE" && (
          <LeftPanelGroupListItem
            url="/employee_add/"
            name="Employee"
            title="Регистрация сотрудника"
            icon={PersonAddIcon}
            leftPanelOpen={open}
          />
        )}
        <LeftPanelGroupListItem
          url="/print_structure/"
          name="Structure"
          title="Печать структуры"
          icon={AccountTreeIcon}
          leftPanelOpen={open}
        />
      </List>
    </Drawer>
  );
};

export default LeftPanel;
