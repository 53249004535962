import {Proxy} from "../../../settings";
import {productType} from "../product/getProducts";
import {baseGetRequest} from "../base/baseGetRequest";

export type modeType = {
  id: number | undefined;
  name: string;
  start_date: string;
  end_date: string | undefined;
  payment_count: number;
  max_payment_count?: number;
  product_set?: Array<productType>;
  is_under_password?: boolean;
  password?: string;
  need_additional_clients: boolean;
};

const getModes = async () =>
  baseGetRequest<modeType>(`${Proxy}/mode/`, undefined);

export default getModes;
