import {Proxy} from "../../../settings";
import {basePostRequest} from "../base/basePostRequest";

export type emailType = {
  subj: string;
  text: string;
  recipients: Array<string>;
};

const postEmail = async (data: emailType) =>
  basePostRequest<emailType>(`${Proxy}/send_mail/`, data);

export default postEmail;
