import {Proxy} from "../../../settings";
import {employeeStatusType, employeeType} from "../employee/getEmployee";
import {baseGetRequest} from "../base/baseGetRequest";

export type structureType = {
  owner_paid_of_signed: number;
  owner_signed: number;
  percent: number;
  rank: number;
  status: employeeStatusType;
  structure_paid_of_signed: number;
  structure_signed: number;
  employee: employeeType;
  operation_commissions: number;
  business_percent: number;
  business_commissions: number;
};

const getStructure = async (
  employeeId: number,
  month: number,
  year: number,
  userLogout?: Function
) =>
  baseGetRequest<Array<structureType>>(`${Proxy}/get_structure/`, {
    employee: employeeId,
    month: `${month}-${year}`
  });

export default getStructure;
