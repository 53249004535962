import {
  Table,
  TableGroupRow,
  TableHeaderRow,
  TableHeaderRowProps,
  TableSelection,
  TableSelectionProps,
  TableTreeColumn,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {withStyles} from "@mui/styles";
import {useWindowDimensions} from "../../../common/customHooks";
import theme from "../../../theme";

const styles = {
  borderLeft: "1px solid rgba(224, 224, 224, 1)",
  paddingTop: "9px",
  paddingBottom: "9px",
  "&:last-child": {
    borderRight: "1px solid rgba(224, 224, 224, 1)"
  },
  "&:first-child": {
    paddingLeft: theme.spacing(1)
  }
};
const stylesHeader = {
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  background: "#F8F8F8"
};
const stylesSelection = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  "&:first-child": {
    paddingLeft: 0
  }
};
const stylesWrap = {
  whiteSpace: "normal",
  wordBreak: "break-all"
};

export const StyledTableHeaderCell = withStyles({
  cell: {...styles, ...stylesHeader}
})(TableHeaderRow.Cell);

export const StyledTableHeaderRow = ({...props}: TableHeaderRowProps) => {
  return (
    <TableHeaderRow
      {...props}
      cellComponent={({...restProps}: any) => {
        return <StyledTableHeaderCell {...restProps} />;
      }}
    />
  );
};

const StyledVirtualTableCell = withStyles({
  cell: {...styles}
})(VirtualTable.Cell);

const StyledVirtualTableCellWrap = withStyles({
  cell: {...styles, ...stylesWrap} as any
})(VirtualTable.Cell);

const StyledStubCell = withStyles({
  cell: {...styles}
})(VirtualTable.StubCell);

const StyledHeaderStubCell = withStyles({
  cell: {...styles, ...stylesHeader}
})(VirtualTable.StubHeaderCell);

const StyledNoDataCell = withStyles({
  cell: {
    borderBottom: "none"
  }
})(VirtualTable.NoDataCell);

export const StyledVirtualTable = ({wrap, ...props}: any) => {
  const {pagination, height} = props;
  const {height: windowHeight} = useWindowDimensions();
  let tableHeight = 0;
  pagination
    ? (tableHeight = windowHeight - 226)
    : (tableHeight = windowHeight - 186);
  return (
    <VirtualTable
      cellComponent={(props: Table.DataCellProps) => {
        if (wrap) {
          return <StyledVirtualTableCellWrap {...props} />;
        } else {
          let title = "";

          //exclude title from some fields
          if (
            props.value &&
            ![
              "status",
              "graphical_status",
              "is_project",
              "is_profile_device",
              "is_profile_app",
              "is_inherited",
              "is_active",
              "is_release",
              "devices"
            ].includes(props.column.name)
          ) {
            title = props.value;
          }
          return <StyledVirtualTableCell {...props} title={title} />;
        }
      }}
      stubCellComponent={(props: TableGroupRow.StubCellProps) => {
        return <StyledStubCell {...props} />;
      }}
      stubHeaderCellComponent={(props: TableGroupRow.StubCellProps) => {
        return <StyledHeaderStubCell {...props} />;
      }}
      noDataCellComponent={(props: Table.NoDataCellProps) => {
        return <StyledNoDataCell {...props} />;
      }}
      {...props}
      messages={{noData: "Нет данных"}}
      height={height ? height : tableHeight}
    />
  );
};

export const StyledSelectionCell = withStyles({
  cell: {...styles, ...stylesSelection}
})(TableSelection.Cell);

export const StyledSelectionCellDisabled = withStyles({
  cell: {...styles, ...stylesSelection}
})(TableSelection.HeaderCell);

export const StyledHeaderSelectionCell = withStyles({
  cell: {
    ...styles,
    ...stylesHeader,
    ...stylesSelection
  }
})(TableSelection.HeaderCell);

export const StyledTableSelection = ({...props}: TableSelectionProps) => {
  return (
    <TableSelection
      cellComponent={(props: any) => {
        return <StyledSelectionCell {...props} />;
      }}
      headerCellComponent={(props: any) => {
        return <StyledHeaderSelectionCell {...props} />;
      }}
      {...props}
    />
  );
};

const StyledTreeCell = withStyles({
  cell: {...styles, paddingTop: 0, paddingBottom: 0}
})(TableTreeColumn.Cell);

export const StyledTableTreeColumn = ({...props}: any) => {
  return (
    <TableTreeColumn
      cellComponent={(props: any) => {
        return <StyledTreeCell {...props} />;
      }}
      {...props}
    />
  );
};

const StyledGroupCell = withStyles({
  cell: {...styles, paddingTop: 0, paddingBottom: 0}
})(TableGroupRow.Cell);

export const StyledTableGroupRow = ({...props}: any) => {
  return (
    <TableGroupRow
      cellComponent={(props: any) => {
        return <StyledGroupCell {...props} />;
      }}
      {...props}
    />
  );
};
