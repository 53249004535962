import {createTheme, Theme} from "@mui/material/styles";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


export const SPACING = 8;

const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1250,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#5d0000"
    },
    secondary: {
      main: "#19857b"
    },
    info: {
      main: "#C4C4C4"
    },
    error: {
      main: "#f50057"
    },
    background: {
      default: "#e2e2e2",
    },
    common: {
      white: "#fff",
      black: "#000",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
    // fix for mui5 issue https://github.com/mui/material-ui/issues/27851
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `8px 24px !important`,
        },
      },
    },
  },
});

export default theme;
