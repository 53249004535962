import {AxiosResponse} from "axios";

export const defaultErrorMessage =
  "Внутренняя ошибка. Свяжитесь с разработчиком системы";

const parseErrorMessage = (error: {response: AxiosResponse}) => {
  if (!error.response) {
    return {
      non_field_error: ["Отсутствует подключение"],
    };
  }
  const {status} = error.response;
  switch (status) {
    case 404:
      return {
        non_field_error: ["Запрашиваемые данные не найдены"],
      };

    case 400:
      if (error.response.data.non_field_error)
        return {
          non_field_error: [error.response.data.non_field_error],
        };
      else {
        return checkFieldErrorType(error.response.data)
          ? error.response.data
          : {
            non_field_error: [defaultErrorMessage],
          };
      }

    default:
      return {
        non_field_error: [defaultErrorMessage],
      };
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkFieldErrorType = (error: any) => {
  const fieldNames = Object.keys(error);
  if (fieldNames.length !== 0) {
    const correctTypeErrors = fieldNames.filter(
      (name) => Array.isArray(error[name]) && typeof error[name][0] === "string"
    );
    if (correctTypeErrors.length === fieldNames.length) {
      return true;
    }
  }

  return false;
};

export default parseErrorMessage;
