import axios from "axios";
import {Proxy} from "../../../settings";
import getToken from "../../getToken";
import {structureType} from "../getStructure/getStructure";
import {errorType} from "../../../components/common/types";

const generateStructureXlsx = async (
  structure: Array<Array<structureType>> | Array<structureType>,
  month: number,
  year: number
): Promise<{
  success: boolean;
  link: string;
  errors: errorType;
}> =>
  new Promise(resolve => {
    axios({
      method: "post",
      url: `${Proxy}/structure_xlsx/`,
      headers: {
        Authorization: `JWT ${getToken()}`,
        "accept-language": "locale"
      },
      params: {
        month: `${month}-${year}`
      },
      data: structure
    })
      .then(response =>
        resolve({
          success: true,
          link: response.data,
          errors: {} as errorType
        })
      )
      .catch(error =>
        resolve({
          success: false,
          link: "",
          errors: error.data as errorType
        })
      );
  });
export default generateStructureXlsx;
