import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {PrimaryButton} from "./PrimaryButtons";

type ButtonProps = {
  onClick: () => void;
  id?: string;
  disabled?: boolean;
};

export const AddNewButton = (props: ButtonProps) => (
  <PrimaryButton title="Добавить" size="medium" {...props} id={"add_button"} />
);

export const SettingsButton = (props: ButtonProps) => {
  return (
    <IconButton
      aria-label="tasks_settings"
      style={{margin: -5}}
      {...props}
      size="large"
    >
      <SettingsIcon />
    </IconButton>
  );
};

export type RefreshButtonProps = {
  update: () => void;
  id?: string;
  disabled?: boolean;
};

export const RefreshButton = (props: RefreshButtonProps) => {
  const {disabled, update, id} = props;

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<RefreshIcon />}
      disabled={disabled}
      onClick={update}
      id={id}
    >
      Обновить
    </Button>
  );
};
