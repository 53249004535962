import PropTypes from 'prop-types'

const smashObject = (obj, resultArr) => {
  resultArr.push(obj)
  if ('children' in obj) {
    obj.children.forEach(child => smashObject(child, resultArr))
  }
}

const myPropTypes = {
  obj: PropTypes.shape({}).isRequired,
  resultArr: PropTypes.array.isRequired,
}

const smashTree = (obj, resultArr) => {
  const props = { obj, resultArr }

  PropTypes.checkPropTypes(myPropTypes, props, 'prop', 'smashTree')

  const smashTreeResult = smashObject(obj, resultArr)

  return smashTreeResult
}

export default smashTree
