import PeopleIcon from "@mui/icons-material/People";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import {CircularProgress, Paper} from "@mui/material";
import React, {useContext, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {AppContext, AppContextType} from "../App-Context";
import BagedIcon from "../common/LeftPanel/BagedIcon";
import {StyledTab, StyledTabs} from "../common/Tabs";
import {TabPanel, tabProps} from "../common/components";
import {useGlobalStyles} from "../common/styles";
import PotentionalClientTable from "./PotentionalClientTable";
import PrePotentionalClientTable from "./PrePotentionalClient/PrePotentionalClientTable";

const useStyles = makeStyles()((theme: any) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

const PotentionalClient = () => {
  const context: AppContextType = useContext(AppContext);
  const {prePotentionalCount} = context;

  const [loading, setLoading] = useState(false);

  const {classes: localClasses} = useStyles();
  const classes = useGlobalStyles();

  const defaultTab = "PotentionalClients";

  const [tab, setTab] = useState(defaultTab);
  const handleTabChange = (e: React.ChangeEvent<{}>, tabValue: string) =>
    setTab(tabValue);

  const [tabsLoading, setTabsLoading] = useState(false);

  return (
    <React.Fragment>
      <main className={localClasses.layout}>
        <Paper className={localClasses.paper}>
          <StyledTabs
            value={tab}
            onChange={handleTabChange}
            aria-label="wrapped label tabs"
          >
            <StyledTab
              label="Контакты"
              icon={<PersonPinIcon />}
              {...tabProps("PotentionalClients")}
              disabled={tabsLoading}
            />

            <StyledTab
              label="Потенциальные контакты"
              {...tabProps("PrePotentionalClients")}
              icon={
                <BagedIcon
                  bageValue={prePotentionalCount}
                  ref={React.createRef()}
                >
                  <PeopleIcon />
                </BagedIcon>
              }
              disabled={tabsLoading}
            />
          </StyledTabs>
          {tab === "PotentionalClients" && (
            <TabPanel value={tab} index="PotentionalClients">
              <PotentionalClientTable />
            </TabPanel>
          )}
          {tab === "PrePotentionalClients" && (
            <TabPanel value={tab} index="PrePotentionalClients">
              <PrePotentionalClientTable />
            </TabPanel>
          )}
          {loading && <CircularProgress className={classes.tableProgress} />}
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default PotentionalClient;
