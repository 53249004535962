import { productType } from '../../common/APIRequests/product/getProducts'

const computePayments = (products: Array<productType>, payment_count: number): {
  firstPayment: number
  monthPayment: number
} => {

  let sum = 0
  if (products.length) {
    sum = products.map((product: productType) => {
      product.count === undefined
        ? product.count = 1
        : undefined
      return (
        product.rur
          ? product.rur * product.count
          : 0 * product.count)
    }).reduce((a, b) => a + b)
  }

  if (payment_count === 1) {
    return ({
      firstPayment: sum,
      monthPayment: 0,
    })
  }

  let firstPayment
  if (payment_count < 5) {
    firstPayment = Math.ceil(sum / payment_count)
  } else {
    firstPayment = Math.ceil(sum * 0.2)
  }

  let sumLeft = sum - firstPayment
  let monthPayment = Math.ceil(sumLeft / (payment_count - 1))

  if (monthPayment % 10) {
    const nMonthPayment = monthPayment - (monthPayment % 10)
    firstPayment = sum - (nMonthPayment * (payment_count - 1))
    monthPayment = nMonthPayment
  }

  return ({
    firstPayment,
    monthPayment,
  })

}

export default computePayments
