import {DialogActions, DialogContent} from "@mui/material";
import moment from "moment";
import React, {useState} from "react";
import postEmail from "../../../common/APIRequests/email/postEmail";
import {potentionalClientType} from "../../../common/APIRequests/potentionalClient/getPotentionalClients";
import postPotentionalClient from "../../../common/APIRequests/potentionalClient/postPotentionalClient";
import {AppContext} from "../../App-Context";
import FormButtons from "../../common/buttons/FormButtons";
import {StyledButton} from "../../common/buttons/StyledButton";
import PopupDialog from "../../common/dialog/PopupDialog";
import BooleanField from "../../common/formFields/BooleanField";
import {alertType, errorType} from "../../common/types";
import ClientAgreement from "../ClientAgreement";
import PotentionalClientInputs from "./PotentionalClientInputs";

type Props = {
  setLoading: Function;
  loading: boolean;
  close: () => void;
  potentionalClient?: potentionalClientType;
  onSubmit?: () => void;
};

export const defaultPotentialClient = {
  id: 0,
  last_name: "",
  first_name: "",
  patronymic: "",
  gender: "M",
  birth_date: moment()
    .subtract(18, "years")
    .subtract(3, "days")
    .format("DD-MM-YYYY"),
  phone: "",
  phone2: "",
  email: ""
} as potentionalClientType;

const PotentialClientForm = (props: Props) => {
  const {loading, setLoading} = props;

  const appContext = React.useContext(AppContext);
  const {setAlert} = appContext;

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState<potentionalClientType>(
    props.potentionalClient || defaultPotentialClient
  );

  const [agree, setAgree] = useState<"1" | "0">("0");
  const [agreeOpen, setAgreeOpen] = useState<boolean>(false);

  const [successOpen, setSuccessOpen] = useState(false as boolean);

  const handleChange = (name: string) => (value: string) =>
    setValues({...values, [name]: value});

  const handleSubmit = async () => {
    if (agree === "1") {
      setErrors({});
      setLoading(true);

      const result = await postPotentionalClient(values);

      if (result.success && result.row) {
        const potentionalClient = result.row;

        const {bonuscard} = potentionalClient;

        if (bonuscard) {
          const {email} = potentionalClient;

          // sends email
          const subj = `Контакт успешно добавлен на Leomax International`;

          const text = `${
            potentionalClient.gender === "M"
              ? "Уважаемый господин,"
              : "Уважаемая госпожа,"
          } ${potentionalClient.last_name} ${potentionalClient.first_name} ${
            potentionalClient.patronymic
          }!<br><br>Поздравляем Вас с регистрацией в клубе "Leomax Excellence".<br>Сообщаем Вам, что в ближайшее время, Вы получите карту привилегий под номером ${
            bonuscard.id
          }.<br><br>---<br>С уважением, служба технической поддержки Leomax International.`;

          await postEmail({
            subj,
            text,
            recipients: [email]
          });

          // do after success (from parent)
          props.onSubmit && props.onSubmit();

          setValues(defaultPotentialClient);

          await props.close();

          setSuccessOpen(true);
        }
      } else {
        setErrors(result.errors);
        setAlert({
          severity: "error",
          message: ["Поля формы содержат ошибки"]
        } as alertType);
      }
    } else {
      setErrors({agree: ["Неоходимо поставить отметку"]});
    }

    setLoading(false);
  };

  return (
    <>
      {successOpen && (
        <PopupDialog
          title="Регистрация клиента успешно завершена"
          onClose={() => setSuccessOpen(false)}
          maxWidth="sm"
        >
          <>
            <DialogContent>Клиент успешно зарегистрирован</DialogContent>
            <DialogActions>
              <StyledButton onClick={() => setSuccessOpen(false)}>
                Продолжить
              </StyledButton>
            </DialogActions>
          </>
        </PopupDialog>
      )}
      {agreeOpen && (
        <PopupDialog
          title="Согласие на обработку персональных данных"
          onClose={() => setAgreeOpen(false)}
          maxWidth="lg"
        >
          <>
            <DialogContent>
              <ClientAgreement />
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={() => setAgreeOpen(false)}>
                Закрыть
              </StyledButton>
            </DialogActions>
          </>
        </PopupDialog>
      )}
      <PotentionalClientInputs
        values={values}
        handleChange={handleChange}
        errors={errors}
        setErrors={setErrors}
        loading={loading}
      />
      <BooleanField
        name="agree"
        label="Согласен на обработку персональных данных"
        setValue={(value: "1" | "0") => {
          setAgree(value);
          setAgreeOpen(true);
        }}
        value={agree}
        errors={errors}
        setErrors={setErrors}
        disabled={loading}
        required={true}
      />
      <FormButtons
        submitMessage="Продолжить"
        loading={loading}
        handleSubmit={handleSubmit}
        handleClose={props.close}
      />
    </>
  );
};

export default PotentialClientForm;
