import {Proxy} from "../../../settings";
import {basePostRequest} from "../base/basePostRequest";

export type loginType = {
  email: string;
  id: number;
  session_key: string;
  token: {'refresh': string, 'access': string};
  username: string;
  role: "MANAGER" | "ADMIN" | "DIRECTOR" | "EMPLOYEE";
  status:
  | "ASSISTENT"
  | "ADVISOR"
  | "EVENT MANAGER"
  | "OPINION LEADER"
  | "CONCEPT SPECIALIST";
  current_state: "NOTENTERED" | "FIRED" | "USUAL";
  employee_id: number;
};

const postLogin = async (data: {username: string; password: string}) =>
  basePostRequest<loginType>(`${Proxy}/session/`, data);

export default postLogin;
