import {Template} from "@devexpress/dx-react-core";
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import getProducts, {
  productType
} from "../../../../../common/APIRequests/product/getProducts";
import {useDebouncedEffect} from "../../../../../common/customHooks";
import {ToolbarItem, ToolbarRoot} from "../../../../common/Toolbar";
import {
  StyledTableHeaderRow,
  StyledVirtualTable
} from "../../../../common/grid/cell";
import {paginationMessages} from "../../../../common/pagination";
import {columnType} from "../../../../common/types";

const columns: columnType = [
  {name: "vendor_code", title: "Артикул"},
  {name: "name", title: "Наименование"},
  {name: "cost", title: "Цена €"},
  {name: "addRemove", title: "Действие", getCellValue: () => "action"}
];

const columnExtensions = [
  {columnName: "vendor_code", width: 100, minWidth: 100},
  {columnName: "name", width: 310, minWidth: 200},
  {columnName: "cost", width: 100, minWidth: 100},
  {columnName: "addRemove", width: "auto", minWidth: 50}
];

type Props = {
  products: Array<productType>;
  setProducts: Function;
  modeId: number | undefined;
  onProductDeselect: Function;
};

const ProductsList = (props: Props) => {
  const {
    products: selectedProducts,
    setProducts: setSelectedProducts,
    modeId,
    onProductDeselect
  } = props;

  const [activePage, setActivePage] = useState(0 as number);
  const [totalPages, setTotalPages] = useState(0 as number);
  const [totalCount, setTotalCount] = useState(0 as number);

  const [searchValue, setSearchValue] = useState("");
  useDebouncedEffect(
    async () => {
      await updateProducts(searchValue);
    },
    700,
    [searchValue]
  );

  const [products, setProducts] = useState([] as Array<productType>);
  useEffect(() => {
    (async () => {
      if (modeId) {
        await updateProducts(searchValue);
      }
    })();
  }, [modeId, activePage]);

  const updateProducts = async (searchValue: string | undefined) => {
    const result = await getProducts(activePage + 1, modeId, searchValue);
    if (result.success && !Array.isArray(result.rows)) {
      const page = result.rows;

      // @ts-ignore
      setTotalPages(page.total_pages);
      // @ts-ignore
      setProducts(page.results);
      // @ts-ignore
      setTotalCount(page.count);
    }
  };

  const AddRemoveFormatter = (data: any) => {
    const selectedProduct = selectedProducts.filter(
      (p: productType) => p.id === data.row.id
    );
    if (selectedProduct.length) {
      return (
        <Button onClick={() => onProductDeselect(data.row.id)}>Убрать</Button>
      );
    } else {
      data.row.count = 1;
      return (
        <Button
          color="primary"
          onClick={() => setSelectedProducts([...selectedProducts, data.row])}
        >
          Добавить
        </Button>
      );
    }
  };
  const AddRemoveProvider = (props: any) => (
    <DataTypeProvider formatterComponent={AddRemoveFormatter} {...props} />
  );

  return (
    <Grid columns={columns} rows={products} getRowId={row => row.id}>
      <StyledVirtualTable
        rowComponent={({row, ...restProps}: any) => (
          <VirtualTable.Row {...restProps} />
        )}
        height="30vh"
        wrapWrapEnabled
        wrap
      />
      {/** Search */}
      <SearchState onValueChange={setSearchValue} />

      {/*Column Resizing*/}
      <TableColumnResizing
        defaultColumnWidths={columnExtensions}
        resizingMode="nextColumn"
        columnExtensions={columnExtensions}
      />

      <AddRemoveProvider for={["addRemove"]} />

      <StyledTableHeaderRow />

      <Toolbar rootComponent={ToolbarRoot} />
      <Template name="toolbarContent">
        <ToolbarItem></ToolbarItem>
      </Template>

      <SearchPanel messages={{searchPlaceholder: "Искать по артикулу..."}} />

      {/*Pagination*/}
      <PagingState
        pageSize={12}
        currentPage={activePage}
        onCurrentPageChange={setActivePage}
      />
      <CustomPaging totalCount={totalCount} />
      <PagingPanel messages={paginationMessages} />
    </Grid>
  );
};

export default ProductsList;
