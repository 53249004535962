import MenuIcon from "@mui/icons-material/Menu";
import {Box, IconButton, Toolbar, Typography} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import {styled} from "@mui/material/styles";
import {PropsWithChildren} from "react";
import {makeStyles} from "tss-react/mui";
import LoginMenu from "./LoginMenu";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})<MuiAppBarProps>(({theme, open}: any) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const useStyles = makeStyles()(theme => ({
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  }
}));

const MenuPanel = (
  props: PropsWithChildren<{
    leftSidebarOpen: boolean;
    openLeftSidebar: (value: boolean) => void;
    userLogout: () => void;
  }>
) => {
  const {classes, cx} = useStyles();
  const {
    children: children,
    openLeftSidebar,
    leftSidebarOpen,
    userLogout
  } = props;

  return (
    //@ts-ignore
    <AppBar position="absolute" open={leftSidebarOpen}>
      <Box>
        <Toolbar sx={{pr: "24px"}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => openLeftSidebar(!leftSidebarOpen)}
            sx={{
              marginRight: "36px",
              ...(leftSidebarOpen && {display: "none"})
            }}
          >
            <MenuIcon />
          </IconButton>
          {typeof children === "string" ? (
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {children}
            </Typography>
          ) : (
            children
          )}
          <LoginMenu userLogout={userLogout} />
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default MenuPanel;
