import {errorType} from "../../../components/common/types";
import {apiClient} from "../../utils/apiClient";

export function baseGetOneRequest<T>(
  url: string,
  params: undefined | any
): Promise<{
  success: boolean;
  row: T;
  errors: errorType;
}> {
  return new Promise(resolve =>
    apiClient({
      method: "get",
      url,
      params
    })
      .then(response =>
        resolve({
          success: true,
          row: response.data,
          errors: {}
        })
      )
      .catch(errors => {
        resolve({
          success: false,
          row: {} as T,
          errors: errors
        });
      })
  );
}
