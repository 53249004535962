import {Proxy} from "../../../settings";
import {baseGetRequest} from "../base/baseGetRequest";

export type productType = {
  id: number | undefined;
  vendor_code: string;
  name: string;
  cost: number;
  Mode: number;
  rur?: number;
  count?: number; // nesessary only in in front-end
};

export type productsPageType = {
  next: string | undefined;
  previous: string | undefined;
  count: number;
  total_pages: number;
  results: Array<productType>;
};

const getProducts = async (
  page: number,
  modeId?: number | undefined,
  search?: string | undefined
) =>
  baseGetRequest<productsPageType>(`${Proxy}/product/`, {
    page,
    mode: modeId,
    search
  });

export default getProducts;
