import {Proxy} from "../../../settings";
import {baseGetOneRequest} from "../base/baseGetOneRequest";

export type employeeStatusType =
  | "ADVISOR"
  | "ASSISTENT"
  | "RECOMMENDER"
  | "OPINION LEADER"
  | "CONCEPT SPECIALIST";

export type employeeType = {
  id: number | undefined;
  User: number | undefined;
  AffilateRegion: number | undefined;
  status: employeeStatusType;
  is_mentor?: boolean;
  last_name: string;
  first_name: string;
  patronymic: string;
  gender: "M" | "F";
  birth_date: string;
  address: string;
  passport_address: string;
  passport_series: string;
  passport_number: string;
  passport_by: string;
  passport_date: string;
  phone: string;
  phone2?: string;
  email: string;
  current_state: "NOTENTERED" | "FIRED" | "USUAL";
  role: "MANAGER" | "ADMIN" | "DIRECTOR" | "EMPLOYEE";
  nalog_type: "IP" | "SZ" | "GPX";
  individual_number?: string;
  individual_date?: string;
  individual_by?: string;
  bank_name: string;
  bank_cipher: string;
  bank_account: string;
  inn: string;
  snils: string;
  level?: number;
  parent?: number;
  snils_copy?: string;
  inn_copy?: string;
  passport_copy?: string;
  date?: string;
  children?: Array<employeeType>;
};

const getEmployee = async (employeeId: number) =>
  baseGetOneRequest<employeeType>(
    `${Proxy}/employee/${employeeId}/`,
    undefined
  );

export default getEmployee;
