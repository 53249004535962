import React, {useEffect, useState} from "react";
import {employeeType} from "../../../common/APIRequests/employee/getEmployee";
import {ownerType} from "../../../common/APIRequests/owner/getOwners";
import smashTree from "../../../common/smashTree";
import BooleanField from "../../common/formFields/BooleanField";
import SelectSingleField from "../../common/formFields/SelectSingleField";
import {errorType, optionType} from "../../common/types";

interface Props {
  employee: employeeType | undefined;
  owner: ownerType;
  setOwner: Function;
  recomendEmployee: string;
  setRecomendEmployee: Function;
  assistentEmployee: string;
  setAssistentEmployee: Function;
}

const OwnerForm = (props: Props) => {
  const {
    employee,
    owner,
    setOwner,
    recomendEmployee,
    setRecomendEmployee,
    assistentEmployee,
    setAssistentEmployee
  } = props;

  const [errors, setErrors] = useState({} as errorType);

  const [recomendEmployeeOptions, setRecomendEmployeeOptions] = useState<
    optionType[]
  >([]);
  const [assistentEmployeeOptions, setAssistentEmployeeOptions] = useState(
    [] as Array<optionType>
  );
  useEffect(() => {
    if (employee && employee.children) {
      const employees: Array<employeeType> = [];
      smashTree(employee, employees);

      const recomendEmployees = employees.filter(
        (employee: employeeType) =>
          employee.status !== "ADVISOR" && employee.status !== "ASSISTENT"
      );

      setRecomendEmployeeOptions(
        recomendEmployees.map((employee: employeeType) => ({
          key: employee.id,
          value: employee.id,
          text: `${employee.last_name} ${employee.first_name} ${employee.patronymic}`
        }))
      );

      const assistentEmployees = employees.filter(
        (employee: employeeType) => employee.status === "ASSISTENT"
      );
      setAssistentEmployeeOptions(
        assistentEmployees.map((employee: employeeType) => ({
          key: employee.id,
          value: employee.id,
          text: `${employee.last_name} ${employee.first_name} ${employee.patronymic}`
        }))
      );
    }
  }, [employee]);

  const handleChange = (name: string) => (value: string) =>
    setOwner({...owner, [name]: value});

  const [loading, setLoading] = useState(false as boolean);

  const handleRecomend = (value: string) => {
    if (parseInt(value)) {
      setAssistentEmployee("0");
    } else {
      setOwner({...owner, Employee: 0});
    }
    setRecomendEmployee(value);
  };

  const handleAssistent = (value: string) => {
    if (parseInt(value)) {
      setRecomendEmployee("0");
    } else {
      setOwner({...owner, Employee: 0});
    }
    setAssistentEmployee(value);
  };

  return (
    <React.Fragment>
      <BooleanField
        setValue={handleRecomend}
        name="recomendEmployee"
        value={recomendEmployee}
        label="Рекомендации"
        disabled={loading}
        errors={errors}
        setErrors={setErrors}
      />
      {recomendEmployee === "1" && (
        <SelectSingleField
          setValue={handleChange("Employee")}
          value={owner.Employee.toString()}
          name="Employee"
          label="ФИО сотрудника, рекомендовавшего клиента"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          //@ts-ignore
          options={recomendEmployeeOptions}
        />
      )}
      {employee && employee.is_mentor && (
        <React.Fragment>
          <BooleanField
            setValue={handleAssistent}
            name="assistentEmployee"
            value={assistentEmployee}
            label="Ассистент"
            errors={errors}
            setErrors={setErrors}
          />
          {assistentEmployee === "1" && (
            <SelectSingleField
              setValue={handleChange("Employee")}
              value={owner.Employee.toString()}
              name="Employee"
              label="ФИО ассистента"
              errors={errors}
              setErrors={setErrors}
              disabled={loading}
              //@ts-ignore
              options={assistentEmployeeOptions}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OwnerForm;
