/**
 * @description return EmployeeId of current user or undefined
 * @returns {string|undefined} role
 */
const getEmployeeId: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('employee_id' in auth) {
      return auth.employee_id
    }
  }
}

export default getEmployeeId
