import {errorType} from "../../../components/common/types";
import {apiClient} from "../../utils/apiClient";

export function baseGetRequestWithCount<T>(
  url: string,
  params: undefined | any
): Promise<{
  success: boolean;
  rows: Array<T>;
  errors: errorType;
  count: number;
}> {
  return new Promise(resolve =>
    apiClient({
      method: "get",
      url,
      params
    })
      .then((response) => {
        if (Array.isArray(response.data)) {
          resolve({
            success: true,
            rows: response.data,
            count: response.data.length,
            errors: {},
          })
        } else {
          resolve({
            success: true,
            rows: response.data.results,
            count: response.data.count,
            errors: {}
          })
        }
      })
      .catch(errors => {
        resolve({
          success: false,
          rows: [],
          count: 0,
          errors
        });
      })
  );
}
