import axios from "axios";
import {Proxy} from "../../../settings";
import getToken from "../../../common/getToken";
import {purchaseType} from "./getPurchases";
import {errorType} from "../../../components/common/types";

const patchPurchaseMultipart = async (
  data: FormData,
  purchaseId: number
): Promise<{
  success: boolean;
  purchase: purchaseType;
  errors: errorType;
}> =>
  new Promise(resolve =>
    axios({
      method: "patch",
      url: `${Proxy}/purchase/${purchaseId}/`,
      headers: {
        Authorization: `JWT ${getToken()}`,
        "accept-language": "locale",
        "Content-Type": "multipart/form-data"
      },
      data
    })
      .then(response =>
        resolve({
          success: true,
          purchase: response.data,
          errors: {}
        })
      )
      .catch(error => {
        const {data: errorData} = error.response;
        resolve({
          success: false,
          purchase: {} as purchaseType,
          errors: errorData
        });
      })
  );

export default patchPurchaseMultipart;
