import {Proxy} from "../../../settings";
import {purchaseType} from "./getPurchases";
import {baseGetRequest} from "../base/baseGetRequest";

const generateAgreement = async (purchaseId: number) =>
  baseGetRequest<purchaseType>(
    `${Proxy}/generate_agreement/?purchase_id=${purchaseId}`,
    undefined
  );

export default generateAgreement;
