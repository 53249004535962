import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {productType} from "../../../../../common/APIRequests/product/getProducts";
import theme from "../../../../../theme";

const useStyles = makeStyles()({
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
});

type Props = {
  selectedProducts: Array<productType>;
};

const TotalLine = (props: Props) => {
  const {selectedProducts} = props;

  const sum = selectedProducts
    .map((p: productType) => p.rur && p.count && p.rur * p.count)
    .reduce((a, b) => a && b && a + b);

  const sumEur = selectedProducts
    .map((p: productType) => p.cost && p.count && p.cost * p.count)
    .reduce((a, b) => a && b && a + b);

  const {classes} = useStyles();

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>
            <b>Итого в рублях:</b>
          </TableCell>
          <TableCell align="right">
            <b>{sum} ₽</b>
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell colSpan={2}>
            <b>Итого в евро:</b>
          </TableCell>
          <TableCell align="right">
            <b>{sumEur} €</b>
          </TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
  );
};

export default TotalLine;
