import {DataTypeProvider} from "@devexpress/dx-react-grid";
import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  CircularProgress,
  CircularProgressProps,
  Slide,
  Snackbar,
  Typography
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {CSSProperties, useState} from "react";
import {alertType} from "./types";

export const tabProps = (index: string) => ({
  value: index,
  id: `wrapped-tab-${index}`,
  "aria-controls": `wrapped-tabpanel=${index}`
});

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

export const TabPanel = (props: any) => {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
      {...a11yProps(index)}
    >
      {children}
    </Typography>
  );
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

export const Spacer = (props: {height?: number; width?: number}) => (
  <div
    style={{
      height: props.height || "inherit",
      width: props.width || "inherit"
    }}
  />
);

export const LoadingIndicator = (
  props: CircularProgressProps & {styleProps?: CSSProperties}
) => {
  const {styleProps, ...progressProps} = props;
  return (
    <CircularProgress
      id="loading-indicator"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 1,
        ...styleProps
      }}
      {...progressProps}
    />
  );
};

const BooleanFormatter = (data: any) => {
  let result = <React.Fragment />;
  data.value === 1 && (result = <CheckIcon style={{color: "green"}} />);
  return result;
};

export const BooleanTypeProvider = (props: any) => {
  return <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;
};

export const SnackbarAlert = (props: {
  alert: alertType;
  setAlert: Function;
}) => {
  const {alert, setAlert} = props;

  const [open, setOpen] = useState(alert ? true : false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setAlert(undefined);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={alert.severity} onClose={handleClose}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};
