import {Button, CircularProgress} from "@mui/material";
import {withStyles} from "@mui/styles";
import theme from "../../../theme";

export const StyledButton = withStyles({
  root: {
    margin: theme.spacing(1)
  }
})(Button);

export const BigButton = withStyles({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  }
})(Button);

export const StyledProgress = withStyles({
  root: {
    position: "absolute",
    left: "50%",
    marginLeft: "-12px"
  }
})(CircularProgress);
