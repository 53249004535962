import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import {Box, IconButton, Typography} from "@mui/material";
import React from "react";
import {productType} from "../../../../../common/APIRequests/product/getProducts";

type Props = {
  onProductCountUpdate: Function;
  product: productType;
  products: Array<productType>;
};

const CountInput = (props: Props) => {
  const {product, products, onProductCountUpdate} = props;

  const handleCountChange = (product: productType, count: number) => {
    onProductCountUpdate(product.id, count);
  };
  return (
    <React.Fragment>
      {product && (
        <Box>
          <Typography>
            {product.count}
            <IconButton
              size="small"
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() =>
                product.count && handleCountChange(product, product.count + 1)
              }
            >
              <ArrowUpwardIcon />
            </IconButton>
            {product.count && product.count > 1 && (
              <IconButton
                size="small"
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  product.count && handleCountChange(product, product.count - 1)
                }
              >
                <ArrowDownwardIcon />
              </IconButton>
            )}
          </Typography>
          {/* <StringField
            key={product.id}
            setValue={handleCountChange(`${product.id}`)}
            value={product.count ? product.count.toString() : "0"}
            name={`${product.id}`}
            label="шт."
            errors={{}}
            setErrors={() => undefined}
            sanitizer={sanitizeInt}
            disabled
          /> */}
        </Box>
      )}
    </React.Fragment>
  );
};

export default CountInput;
