/**
 * @description gets username from localstorage
 * @function
 * @returns {string|undefined} name of user or undefined
 */
const getToken: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('username' in auth) {
      return auth.username
    }
  }
}

export default getToken
