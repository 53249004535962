import {Button, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {StyledProgress} from "./StyledButton";

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    margin: 0,
    padding: theme.spacing(1.3),
    marginBottom: theme.spacing(2)
  }
}));

export type Props = {
  children: JSX.Element | string;
  loading: boolean;
  fullWidth: boolean;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  submit: any;
  type?: "submit";
  setRef?: Function;
};

const ButtonLoading = (props: Props) => {
  const {loading, fullWidth, children, color, submit, type, setRef} = props;

  const {classes} = useStyles();

  return (
    <Button
      fullWidth={fullWidth}
      variant="contained"
      color={color}
      className={classes.button}
      disabled={loading}
      onClick={loading ? (e: any) => e.preventDefault() : submit}
      type={type}
      ref={element => setRef && setRef(element)}
    >
      {children}
      {loading && <StyledProgress size={28} />}
    </Button>
  );
};

export default ButtonLoading;
