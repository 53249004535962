/**
 * @description return role of current user or false
 * @returns {string|undefined} role
 */
const getRole: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('role' in auth) {
      return auth.role
    }
  }
}

export default getRole
