import axios from "axios";
import {Proxy} from "../../settings";

const getToken: Function = (): string => {
  const authStorage = localStorage.getItem("auth");
  if (!authStorage) {
    return "";
  }
  try {
    const auth = JSON.parse(authStorage);
    return auth.token.access || "";
  } catch (error) {
    return "";
  }
};

export const apiClient = axios.create({
  baseURL: Proxy
});
apiClient.defaults.headers.common["accept-language"] = "locale";

// add token to request
apiClient.interceptors.request.use(function (config) {
  config.headers.Authorization = `JWT ${getToken()}`;
  return config;
});
