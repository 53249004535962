import {
  ColumnChooser,
  ColumnChooserProps
} from "@devexpress/dx-react-grid-material-ui";
import DehazeIcon from "@mui/icons-material/Dehaze";
import {IconButton} from "@mui/material";

const ToggleButton = ({...props}) => (
  <IconButton
    onClick={props.onToggle}
    // buttonRef={props.buttonRef}
    title="Скрыть/показать столбцы"
  >
    <DehazeIcon />
  </IconButton>
);

const StyledColumnChooser = (props: ColumnChooserProps) => {
  return (
    <ColumnChooser
      {...props}
      messages={{
        showColumnChooser: "Скрыть/показать столбцы "
      }}
      toggleButtonComponent={ToggleButton}
    />
  );
};

export default StyledColumnChooser;
