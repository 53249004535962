import {useState} from "react";
import getPrePotentionalClients, {
  prePotentionalClientType
} from "../../../common/APIRequests/prePotentionalClient/getPrePotentionalClients";
import Column from "../../Tables/Column";
import CoreTable, {UpdateRowsType} from "../../Tables/CoreTable";
import PopupDialog from "../../common/dialog/PopupDialog";
import PrePotentionalClientForm from "./PrePotentionalClientForm";

const PrePotentionalClientColumns = [
  new Column("id", "ID", 130, {hiddenByDefault: true}),
  new Column("last_name", "Фамилия", 180),
  new Column("first_name", "Имя", 180),
  new Column("patronymic", "Отчество", 180),
  new Column("phone", "Телефон", 140),
  new Column("potentional_client", "Данные договора", 200)
];

const PrePotentionalClientTable = () => {
  const updateRows: UpdateRowsType<prePotentionalClientType> = () =>
    getPrePotentionalClients();

  const [selected, setSelected] = useState<
    prePotentionalClientType | undefined
  >(undefined);

  return (
    <>
      {selected && (
        <PopupDialog
          title="Потенциальный контакт"
          onClose={() => setSelected(undefined)}
          maxWidth="sm"
        >
          <PrePotentionalClientForm
            prePotentionalClient={selected}
            close={() => setSelected(undefined)}
          />
        </PopupDialog>
      )}
      <CoreTable
        name={"pre_potentional_client"}
        onRowClick={(row: prePotentionalClientType) => setSelected(row)}
        columns={PrePotentionalClientColumns}
        defaultSorting={{columnName: "last_name", direction: "asc"}}
        getRowId={row => row.id}
        updateRows={updateRows}
        height={"58vh"}
      />
    </>
  );
};

export default PrePotentionalClientTable;
