import {Box, CircularProgress, CssBaseline, Theme} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Route, BrowserRouter as Router} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import getEmployee, {
  employeeType
} from "./common/APIRequests/employee/getEmployee";
import {loginType} from "./common/APIRequests/login/postLogin";
import getCountPrePotentional from "./common/APIRequests/prePotentionalClient/getCounntPrePotentional";
import getEmployeeId from "./common/getEmployeeId";
import {apiClient} from "./common/utils/apiClient";
import getLoginInfo from "./common/utils/getLoginInfo";
import parseErrorMessage from "./common/utils/parseErrorMessage";
import {AppContext} from "./components/App-Context";
import AddAgreement from "./components/addAgreement/AddAgreementNew";
import AddEmployee from "./components/addEmployee/AddEmployee";
import PotentionalClient from "./components/addPotentialClient/PotentionalClient";
import {SnackbarAlert} from "./components/common/components";
import {alertType, errorType} from "./components/common/types";
import Content from "./components/content";
import Home from "./components/home/Home";
import PrintStructure from "./components/printStructure/PrintStructure";

require("moment/locale/ru");
moment.locale("ru");
require("dayjs/locale/ru");
dayjs.locale("ru");

const useStyles = makeStyles()((theme: Theme) => {
  return {
    paperLoader: {
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: 9999,
      backgroundColor: theme.palette.background.paper
    },
    tableProgress: {
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 1
    }
  };
});

const App = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [alert, setAlert] = useState<alertType | undefined>(undefined);
  const loginInfo = getLoginInfo() as loginType;

  useEffect(() => {
    const errorInterceptor = apiClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          userLogout();
          return;
        } else if (error.response && error.response.status === 403) {
          console.warn(error.response.data);
          return;
        }
        const errors: errorType = parseErrorMessage(error);

        if (errors.non_field_error) {
          setAlert({
            severity: "error",
            message: errors.non_field_error
          });
          delete errors.non_field_error;
        }
        return Promise.reject(errors);
      }
    );
    return () => {
      apiClient.interceptors.response.eject(errorInterceptor);
    };
  });

  const userLogin = useCallback(
    (login: loginType) => {
      localStorage.setItem("auth", JSON.stringify(login));
      setIsAuth(true);
      setLoaded(true);
    },
    [setIsAuth, setLoaded]
  );

  const userLogout = useCallback(() => {
    localStorage.removeItem("auth");
    setIsAuth(false);
  }, [setIsAuth]);

  // employee state
  const [employee, setEmployee] = useState<employeeType>({} as employeeType);

  const updateEmployee = () =>
    getEmployee(getEmployeeId()).then(result => {
      setEmployee(result.row);
    });

  //potentional client state
  const [prePotentionalCount, setPrePotentionalCount] = useState<
    number | number[]
  >(0);

  const updatePrePotentionalCount = () =>
    getCountPrePotentional().then(result => {
      setPrePotentionalCount(result.rows);
    });

  useEffect(() => {
    if (!isAuth) return;

    setLoading(true);
    Promise.all([updateEmployee(), updatePrePotentionalCount()]).finally(() => {
      setLoaded(true);
      setLoading(false);
    });
  }, [isAuth]);

  const props = useMemo(
    () => ({
      employee,
      isAuth,
      setLoaded,
      userLogin,
      userLogout
    }),
    [isAuth, userLogin, employee, userLogout, setLoaded]
  );

  const context = useMemo(
    () => ({
      setAlert,
      employee,
      updateEmployee,
      prePotentionalCount,
      updatePrePotentionalCount,
      loading
    }),
    [
      setAlert,
      employee,
      updateEmployee,
      prePotentionalCount,
      updatePrePotentionalCount,
      loading
    ]
  );

  const HomeContent = () => (
    <Content barContent="Главная" Cont={Home} {...props} />
  );

  const AddEmployeeContent = () => (
    <Content
      barContent="Регистрация сотрудника"
      Cont={AddEmployee}
      {...props}
    />
  );

  const PotentionalClientContent = () => (
    <Content barContent="Контакт" Cont={PotentionalClient} {...props} />
  );

  const AddAgreementContent = () => (
    <Content barContent="Договор с клиентом" Cont={AddAgreement} {...props} />
  );

  const PrintStructureContent = () => (
    <Content barContent="Печать структуры" Cont={PrintStructure} {...props} />
  );

  const {classes} = useStyles();

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      {!loaded && isAuth && (
        <div className={classes.paperLoader}>
          <CircularProgress className={classes.tableProgress} disableShrink />
        </div>
      )}
      <AppContext.Provider value={context}>
        <Router>
          <Route exact path="/" render={HomeContent} />
          <Route exact path="/employee_add/" render={AddEmployeeContent} />
          <Route
            exact
            path="/potentional_client_add/"
            render={PotentionalClientContent}
          />
          <Route exact path="/purchase_add/" render={AddAgreementContent} />
          <Route
            exact
            path="/print_structure/"
            render={PrintStructureContent}
          />
        </Router>
      </AppContext.Provider>
      {alert && <SnackbarAlert alert={alert} setAlert={setAlert} />}
    </Box>
  );
};

export default App;
