import {
  ColumnChooserProps,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {IconButton, TextField} from "@mui/material";

const SearchInput = (props: SearchPanel.InputProps) => (
  <TextField
    value={props.value}
    onChange={(e: any) => props.onValueChange(e.target.value)}
    placeholder={props.getMessage("searchPlaceholder")}
    InputProps={{
      startAdornment: <SearchIcon />,
      endAdornment: (
        <IconButton
          onClick={() => props.onValueChange("")}
          size="small"
          disabled={!props.value}
        >
          <ClearIcon />
        </IconButton>
      )
    }}
  />
);

const StyledSearchPanel = (props: ColumnChooserProps) => (
  <SearchPanel
    {...props}
    messages={{searchPlaceholder: "Искать..."}}
    inputComponent={SearchInput}
  />
);

export default StyledSearchPanel;
