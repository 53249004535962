import {Box, CircularProgress, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {BottomToolbarWrapper, ToolbarItem, ToolbarSeparator} from "../Toolbar";
import {BigButton} from "./StyledButton";

const FormButtons = (props: {
  submitMessage: string;
  cancelMessage?: string;
  loading: boolean;
  disabled?: boolean;
  handleSubmit: Function;
  handleClose: Function;
  children?: any;
}) => {
  const {
    loading,
    disabled,
    submitMessage,
    cancelMessage,
    handleSubmit,
    handleClose,
    children
  } = props;

  const useStyles = makeStyles()((theme: Theme) => ({
    buttonProgress: {
      position: "absolute",
      left: "50%",
      marginLeft: "-12px"
    }
  }));

  const {classes} = useStyles();

  return (
    <BottomToolbarWrapper>
      {children}
      <ToolbarSeparator />
      <ToolbarItem>
        <BigButton
          variant="contained"
          color="primary"
          disabled={disabled || loading}
          onClick={(e: any) => (loading ? e.preventDefault() : handleSubmit())}
        >
          {submitMessage}
          {loading && (
            <CircularProgress size={28} className={classes.buttonProgress} />
          )}
        </BigButton>
      </ToolbarItem>
      <Box>
        <BigButton
          fullWidth
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={() => handleClose()}
        >
          {cancelMessage || "Закрыть"}
        </BigButton>
      </Box>
    </BottomToolbarWrapper>
  );
};

export default FormButtons;
