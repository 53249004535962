import {CircularProgress} from "@mui/material";
import React, {useState} from "react";
import {useGlobalStyles} from "../common/styles";
import PotentialClientForm from "./AddPotentionalClientForm/PotentialClientForm";

type Props = {
  close: () => void;
};

const AddPotentionalClient = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const classes = useGlobalStyles();

  return (
    <React.Fragment>
      <PotentialClientForm
        setLoading={setLoading}
        loading={loading}
        close={props.close}
      />
      {loading && <CircularProgress className={classes.tableProgress} />}
    </React.Fragment>
  );
};

export default AddPotentionalClient;
