import {FormControl, FormHelperText, TextField} from "@mui/material";
import React from "react";
import theme from "../../../theme";
import {fieldProps} from "../types";

const StringField = (props: fieldProps) => {
  const {
    setValue,
    value,
    name,
    label,
    errors,
    inputProps,
    sanitizer,
    multiline,
    style,
    disabled,
    helperText,
    required
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete errors[name];
    const value = sanitizer ? sanitizer(e.target.value) : e.target.value;
    setValue(value);
  };

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        error={!!errors[name]}
        style={style ? style : {marginBottom: theme.spacing(2)}}
      >
        <TextField
          variant="standard"
          fullWidth
          id={name}
          name={name}
          type="text"
          error={!!errors[name]}
          autoComplete="off"
          label={label}
          onChange={handleChange}
          value={value}
          aria-describedby={`component-error-${name}`}
          inputProps={inputProps}
          multiline={multiline || false}
          disabled={disabled || false}
          helperText={helperText}
          required={required}
        />
        {errors[name] && (
          <FormHelperText id={`component-error-${name}`}>
            {errors[name]}
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

export default StringField;
