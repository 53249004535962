import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {passportType} from "../../../../common/APIRequests/passport/getPassports";
import getPotentionalClients, {
  potentionalClientType
} from "../../../../common/APIRequests/potentionalClient/getPotentionalClients";
import {AppContext} from "../../../App-Context";
import {defaultPotentialClient} from "../../../addPotentialClient/AddPotentionalClientForm/PotentialClientForm";
import PotentionalClientInputs from "../../../addPotentialClient/AddPotentionalClientForm/PotentionalClientInputs";
import BooleanField from "../../../common/formFields/BooleanField";
import SelectSingleField from "../../../common/formFields/SelectSingleField";
import {errorType, optionType} from "../../../common/types";
import PassportInputs from "./PassportInputs";

export const defaultPassport = {
  id: undefined,
  PotentionalClient: undefined,
  passport_address: "",
  passport_series: "",
  passport_number: "",
  passport_by: "",
  passport_date: moment()
    .subtract(1, "days")
    .format("DD-MM-YYYY")
};

type Props = {
  client: potentionalClientType;
  setClient: Function;
  passport: passportType;
  setPassport: Function;
  errors: errorType;
  setErrors: (errors: errorType) => void;
};

const PassportForm = (props: Props) => {
  const {client, setClient, passport, setPassport, errors, setErrors} = props;

  const appContext = useContext(AppContext);

  const [loading, setLoading] = useState(false as boolean);

  useEffect(() => {
    if (clients.length && passport.PotentionalClient && passport) {
      (async () => {
        let clients = await updateClients();
        setErrors({});

        if (Array.isArray(clients)) {
          const c = clients.filter(
            (c: potentionalClientType) =>
              passport && c.id === passport.PotentionalClient
          )[0];
          setClient(c);

          const clientPass = c.passport;
          clientPass ? setPassport(clientPass) : setPassport(defaultPassport);
        }
      })();
    }
  }, [passport.PotentionalClient]);

  const [clients, setClients] = useState([] as Array<potentionalClientType>);
  useEffect(() => {
    (async () => {
      await updateClients();
    })();
  }, []);

  const updateClients = async () => {
    const result = await getPotentionalClients();

    if (result.success) {
      const clients = result.rows;

      if (Array.isArray(clients)) {
        await setClients(clients);
      }
    }
    return result.rows;
  };

  const [clientOptions, setClientOptions] = useState([] as Array<optionType>);
  useEffect(() => {
    const options = clients.map((client: potentionalClientType) => ({
      key: client.id,
      value: client.id,
      text: `${client.last_name} ${client.first_name} ${
        client.patronymic
      } (паспорт: ${
        client.passport
          ? client.passport.passport_series.concat(
              client.passport.passport_number
            )
          : "нет"
      })`
    }));
    setClientOptions(options);
  }, [clients.length]);

  const [isClient, setIsClient] = useState("0" as string);
  useEffect(() => {
    !isClient && setPassport(defaultPassport);
  }, [isClient]);

  const handleIsClient = (value: string) => {
    if (!parseInt(value)) {
      setClient(defaultPotentialClient);
      setPassport(defaultPassport);
    }
    setIsClient(value);
  };

  return (
    <React.Fragment>
      <BooleanField
        setValue={handleIsClient}
        name="isClient"
        value={isClient}
        errors={errors}
        setErrors={setErrors}
        label="Является клиентом Leomax International"
        disabled={loading}
      />
      {isClient === "1" && (
        <SelectSingleField
          setValue={(value: string) =>
            setPassport({...passport, PotentionalClient: parseInt(value)})
          }
          value={client.id ? client.id.toString() : ""}
          name="id"
          label="Фамилия имя отчество клиента"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          //@ts-ignore
          options={clientOptions}
        />
      )}

      <PotentionalClientInputs
        values={client ? client : defaultPotentialClient}
        handleChange={(name: string) => (value: string) =>
          setClient({...client, [name]: value})}
        errors={errors}
        setErrors={setErrors}
        loading={loading}
        isClient={isClient}
      />
      <PassportInputs
        values={passport}
        handleChange={(name: string) => (value: string) =>
          setPassport({...passport, [name]: value})}
        errors={errors}
        setErrors={setErrors}
        loading={loading}
        isClient={isClient}
      />
    </React.Fragment>
  );
};

export default PassportForm;
