/**
 * @description Returns session key if user authorizes earlier or undefined
 * @function
 * @returns {string|undefined} session key string or undefined
 */
const getSessionKey: Function = (): string | undefined => {
  const authStorage = localStorage.getItem('auth')

  if (authStorage) {
    const auth = JSON.parse(authStorage)

    if ('session_key' in auth) {
      return auth.session_key
    }
  }
}

export default getSessionKey
