import moment from "moment";
import React, {useContext, useEffect, useState} from "react";

import MaskedInput from "react-text-mask";
import getAffilateRegions from "../../common/APIRequests/affilateRegion/getAffilateRegions";
import {affilateRegionType} from "../../common/APIRequests/affilateRegion/getAffiliateRegion";
import getEmployee, {
  employeeType
} from "../../common/APIRequests/employee/getEmployee";
import postEmployee from "../../common/APIRequests/employee/postEmployee";
import getEmployeeId from "../../common/getEmployeeId";
import smashTree from "../../common/smashTree";
import {AppContext} from "../App-Context";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarSeparator
} from "../common/Toolbar";
import {StyledButton} from "../common/buttons/StyledButton";
import {Transition} from "../common/components";
import DraggableDialog from "../common/dialog/DraggableDialog";
import DraggableDialogTitle from "../common/dialog/DraggableDialogTitle";
import BooleanField from "../common/formFields/BooleanField";
import PickerDate from "../common/formFields/PickerDate";
import SelectSingleField from "../common/formFields/SelectSingleField";
import StringField from "../common/formFields/StringField";
import {sanitizeInt} from "../common/formFields/sanitizers";
import {
  genderOptions,
  nalogTypeOptions,
  statusOptions
} from "../common/options";
import {alertType, errorType, optionType} from "../common/types";
import AgreementText from "./Agreement";

import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Input,
  InputLabel
} from "@mui/material";
//@ts-ignore
import mpass from "mpass";
import {transliterate} from "transliteration";
import postEmail from "../../common/APIRequests/email/postEmail";
import deleteEmployee from "../../common/APIRequests/employee/deleteEmployee";
import patchEmployee from "../../common/APIRequests/employee/patchEmployee";
import postUser from "../../common/APIRequests/user/postUser";
import theme from "../../theme";
import ButtonLoading from "../common/buttons/ButtonLoading";
import PhoneField from "../common/formFields/PhoneField";

type Props = {
  employeeId: number | undefined;
  setLoading: Function;
  loading: boolean;
};

export const defaultEmployee = {
  id: undefined,
  User: undefined,
  AffilateRegion: undefined,
  status: "ADVISOR",
  last_name: "",
  first_name: "",
  patronymic: "",
  gender: "M",
  birth_date: moment()
    .subtract(18, "years")
    .subtract(3, "days")
    .format("DD-MM-YYYY"),
  address: "",
  passport_address: "",
  passport_series: "",
  passport_number: "",
  passport_by: "",
  passport_date: moment()
    .subtract(1, "days")
    .format("DD-MM-YYYY"),
  phone: "",
  phone2: "",
  email: "",
  current_state: "NOTENTERED",
  role: "EMPLOYEE",
  nalog_type: "GPX",
  individual_number: undefined,
  individual_date: moment()
    .subtract(1, "days")
    .format("DD-MM-YYYY"),
  individual_by: "",
  bank_name: "",
  bank_cipher: "",
  bank_account: "",
  inn: "",
  snils: "",
  parent: undefined
};

const TextMaskCustom = React.forwardRef(
  (props: any, ref: React.Ref<unknown>) => {
    const {...other} = props;
    return (
      <MaskedInput
        {...other}
        //@ts-ignore
        ref={ref ? ref.inputElement : null}
        mask={[
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/
        ]}
        placeholderChar={"\u2000"}
        showMask
      />
    );
  }
);

const EmployeeForm = (props: Props) => {
  const {setLoading, loading} = props;
  const appContext = useContext(AppContext);
  const {setAlert} = appContext;

  const [errors, setErrors] = useState({} as errorType);

  const [values, setValues] = useState(defaultEmployee as employeeType);
  const [parentOptions, setParentOptions] = useState([] as Array<optionType>);

  const [affilateRegionOptions, setAffilateRegionOptions] = useState(
    [] as Array<optionType>
  );

  useEffect(() => {
    (async () => {
      const getAffilateRegionResult = await getAffilateRegions();

      const affilateRegions = getAffilateRegionResult.rows;

      if (Array.isArray(affilateRegions)) {
        const affilateRegionOptions = affilateRegions.map(
          (region: affilateRegionType) => ({
            key: region.id,
            text: region.name,
            value: region.id
          })
        );

        const getEmployeeResult = await getEmployee(getEmployeeId());
        const employee = getEmployeeResult.row;

        const smashedEmployee: Array<employeeType> = [];

        smashTree(employee, smashedEmployee);

        const parentOptions = smashedEmployee.map(employee => ({
          key: employee.id,
          text: `${employee.last_name} ${employee.first_name} ${employee.patronymic}`,
          value: employee.id
        }));

        setAffilateRegionOptions(affilateRegionOptions);

        setParentOptions(parentOptions);

        setValues({...values, parent: parentOptions[0].value});
      }
    })();
  }, []);

  const [matchWithPassAddr, setMatchWithPassAddr] = useState("0" as string);

  const [agree, setAgree] = useState(false as boolean);
  useEffect(() => {
    agree && setAgreementOpen(true);
  }, [agree]);

  const [successOpen, setSuccessOpen] = useState(false as boolean);
  useEffect(() => {
    !successOpen && setValues(defaultEmployee as employeeType);
  }, [successOpen]);

  const [agreementOpen, setAgreementOpen] = useState(false as boolean);

  const handleChange = (name: string) => (value: string) =>
    setValues({...values, [name]: value});

  // address match registration address
  const handleMatchWithPassAddr = (value: string) => {
    if (parseInt(value)) {
      if (values.passport_address) {
        setValues({...values, address: values.passport_address});
      }
    } else {
      setValues({...values, address: ""});
    }
    setMatchWithPassAddr(value);
  };

  const handleSubmit = async () => {
    if (agree) {
      setErrors({});
      setLoading(true);

      const {parent} = values;

      if (parent) {
        const postEmployeeResult = await postEmployee(values);

        if (postEmployeeResult.success && postEmployeeResult.row) {
          const employee = postEmployeeResult.row;

          if (!Array.isArray(employee)) {
            const username = `${transliterate(employee.last_name)
              .replace(" ", "")
              .toLowerCase()}${transliterate(employee.first_name)
              .replace(" ", "")
              .toLowerCase()
              .charAt(0)}${transliterate(employee.patronymic)
              .replace(" ", "")
              .toLowerCase()
              .charAt(0)}`;
            const password = mpass(2);

            const createUserResult = await postUser({
              username,
              password,
              email: employee.email
            });

            if (createUserResult.success) {
              if (createUserResult.row) {
                const userId = createUserResult.row.id;

                // update employee to fill User_id with created User
                if (employee.id) {
                  await patchEmployee(employee.id, {
                    User: userId
                  } as employeeType);

                  const subj = `Регистрация на портале Leomax International`;

                  const text = `${
                    employee.gender === "M"
                      ? "Уважаемый господин,"
                      : "Уважаемая госпожа,"
                  } ${employee.last_name} ${employee.first_name} ${
                    employee.patronymic
                  }!<br><br>Поздравляем Вас с регистрацией в качестве партнёра(id: ${
                    employee.id
                  }) Leomax International.<br>Ваш логин для работы на портале продаж: ${username}. Ваш пароль: ${password}<br>Cсылка: <a href="http://myleomaxinternational.com">myleomaxinternational.com</a><br><br>---<br>С уважением, служба технической поддержки Leomax International.`;

                  // sends email
                  await postEmail({
                    subj,
                    text,
                    recipients: [employee.email]
                  });

                  // update state
                  setSuccessOpen(true);
                }
              }
            } else {
              employee.id && (await deleteEmployee(employee.id));
              setErrors(createUserResult.errors);
              if ("username" in createUserResult.errors) {
                setAlert({
                  severity: "error",
                  message: [createUserResult.errors.username[0]]
                } as alertType);
              }
            }
          }

          setMatchWithPassAddr("0");
        } else {
          setErrors(postEmployeeResult.errors);
        }
      } else {
        setErrors({...errors, parent: ["Это поле не может быть пустым"]});
      }
    } else {
      setErrors({agree: ["Неоходимо поставить отметку"]});
    }

    setLoading(false);
  };

  return (
    <React.Fragment>
      {successOpen && (
        <DraggableDialog
          open
          onClose={() => setSuccessOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="sm"
          aria-labelledby="success-modal-open"
        >
          <DraggableDialogTitle id="success-modal-open">
            Регистрация сотрудника успешно завершена
          </DraggableDialogTitle>
          <DialogContent>
            На Адрес нового сотрудника было направлено письмо с именем
            пользователя и паролем
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={() => setSuccessOpen(false)}>
              Продолжить
            </StyledButton>
          </DialogActions>
        </DraggableDialog>
      )}
      {agreementOpen && (
        <DraggableDialog
          open
          onClose={() => setAgreementOpen(false)}
          keepMounted
          TransitionComponent={Transition}
          maxWidth="lg"
          aria-labelledby="agreement-modal-open"
        >
          <DraggableDialogTitle id="agreement-modal-open">
            Согласие на обработку персональных данных
          </DraggableDialogTitle>
          <DialogContent>
            <AgreementText />
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={() => setAgreementOpen(false)}>
              Закрыть
            </StyledButton>
          </DialogActions>
        </DraggableDialog>
      )}

      <form>
        <SelectSingleField
          setValue={handleChange("AffilateRegion")}
          value={values.AffilateRegion ? values.AffilateRegion.toString() : ""}
          name="AffilateRegion"
          label="Филиал"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          //@ts-ignore
          options={affilateRegionOptions}
        />
        <SelectSingleField
          setValue={handleChange("status")}
          value={values.status}
          name="status"
          label="Статус"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          options={statusOptions}
        />
        <StringField
          setValue={handleChange("last_name")}
          value={values.last_name}
          name="last_name"
          label="Фамилия"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 50}}
          required
        />
        <StringField
          setValue={handleChange("first_name")}
          value={values.first_name}
          name="first_name"
          label="Имя"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 50}}
          required
        />
        <StringField
          setValue={handleChange("patronymic")}
          value={values.patronymic}
          name="patronymic"
          label="Отчество"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 50}}
          required
        />
        <SelectSingleField
          setValue={handleChange("gender")}
          value={values.gender}
          name="gender"
          label="Пол"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          options={genderOptions}
        />
        <PickerDate
          //@ts-ignore
          setValue={handleChange("birth_date")}
          value={values.birth_date}
          label="Дата рождения"
          name="birth_date"
          disabled={loading}
          errors={errors}
          maxDate={moment()
            .subtract(18, "years")
            .subtract(3, "days")
            .toDate()}
          required
        />
        <StringField
          setValue={handleChange("passport_address")}
          value={values.passport_address}
          name="passport_address"
          label="Адрес по прописке"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          multiline
          required
        />
        <StringField
          setValue={handleChange("address")}
          value={values.address}
          name="address"
          label="Адрес проживания"
          errors={errors}
          setErrors={setErrors}
          disabled={loading || matchWithPassAddr === "1"}
          multiline
        />
        <BooleanField
          setValue={handleMatchWithPassAddr}
          name="matchWithPassAddr"
          value={matchWithPassAddr}
          errors={errors}
          setErrors={setErrors}
          label="совпадает с адресом проживания"
          disabled={loading || !values.passport_address}
        />
        <StringField
          setValue={handleChange("passport_series")}
          value={values.passport_series}
          name="passport_series"
          label="Серия паспорта"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 4}}
          sanitizer={sanitizeInt}
          required
        />
        <StringField
          setValue={handleChange("passport_number")}
          value={values.passport_number}
          name="passport_number"
          label="Номер паспорта"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 6}}
          sanitizer={sanitizeInt}
          required
        />
        <StringField
          setValue={handleChange("passport_by")}
          value={values.passport_by}
          name="passport_by"
          label="Паспорт выдан"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          multiline
          required
        />
        <PickerDate
          //@ts-ignore
          setValue={handleChange("passport_date")}
          value={values.passport_date}
          label="Дата выдачи паспорта"
          name="passport_date"
          disabled={loading}
          errors={errors}
          maxDate={moment().toDate()}
          required
        />
        <PhoneField
          setValue={handleChange("phone")}
          value={values.phone}
          name="phone"
          label="Контактный телефон (формат 9279999999)"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 12}}
          sanitizer={sanitizeInt}
          required
        />
        <PhoneField
          setValue={handleChange("phone2")}
          value={values.phone2 || ""}
          name="phone2"
          label="Дополнительный телефон (формат 9279999999)"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 12}}
          sanitizer={sanitizeInt}
        />
        <StringField
          setValue={handleChange("email")}
          value={values.email}
          name="email"
          label="Электронная почта"
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 200}}
          disabled={loading}
          required
        />
        <SelectSingleField
          setValue={handleChange("nalog_type")}
          value={values.nalog_type}
          name="nalog_type"
          label="Вид налогообложения"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          options={nalogTypeOptions}
        />
        <StringField
          setValue={handleChange("individual_number")}
          value={values.individual_number || ""}
          name="individual_number"
          label="Номер свидетельства индивидуального предпринимателя (ОГРНИП)"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          inputProps={{maxLength: 15}}
          sanitizer={sanitizeInt}
        />
        <PickerDate
          //@ts-ignore
          setValue={handleChange("individual_date")}
          value={values.individual_date || ""}
          label="Дата выдачи свидетельства индивидуального предпринимательства"
          name="individual_date"
          disabled={loading}
          maxDate={moment().toDate()}
          errors={errors}
          required
        />
        <StringField
          setValue={handleChange("individual_by")}
          value={values.individual_by || ""}
          name="individual_by"
          label="Кем выдано свидетельство индивидуального предпринимателя"
          disabled={values.nalog_type === "IP" ? undefined : true}
          errors={errors}
          setErrors={setErrors}
        />
        <StringField
          setValue={handleChange("bank_name")}
          value={values.bank_name}
          name="bank_name"
          label="Наименование банка"
          disabled={loading}
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 50}}
        />
        <StringField
          setValue={handleChange("bank_cipher")}
          value={values.bank_cipher}
          name="bank_cipher"
          label="Шифр банка"
          disabled={loading}
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 50}}
        />
        <StringField
          setValue={handleChange("bank_account")}
          value={values.bank_account}
          name="bank_account"
          label="Номер счета"
          disabled={loading}
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 20}}
          sanitizer={sanitizeInt}
        />
        <StringField
          setValue={handleChange("inn")}
          value={values.inn}
          name="inn"
          label="ИНН"
          disabled={loading}
          errors={errors}
          setErrors={setErrors}
          inputProps={{maxLength: 12}}
          sanitizer={sanitizeInt}
        />
        <FormControl
          fullWidth
          variant="standard"
          error={errors.snils && true}
          style={{marginBottom: theme.spacing(2)}}
        >
          <InputLabel htmlFor="snils">СНИЛС</InputLabel>
          <Input
            value={values.snils}
            onChange={(e: any) => {
              delete errors.snils;
              setValues({...values, snils: e.target.value});
            }}
            name="snils"
            id="snils"
            inputComponent={TextMaskCustom}
            error={errors.snils && true}
            disabled={loading}
          />
          {errors.snils && <FormHelperText>{errors.snils}</FormHelperText>}
        </FormControl>
        <SelectSingleField
          setValue={handleChange("parent")}
          value={values.parent ? values.parent.toString() : ""}
          name="parent"
          label="Менеджер"
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          //@ts-ignore
          options={parentOptions}
        />

        <BooleanField
          name="agree"
          label="Согласен на обработку персональных данных"
          setValue={() => {
            setAgree(!agree);
            setAgreementOpen(!agree);
          }}
          value={agree ? agree.toString() : "0"}
          errors={errors}
          setErrors={setErrors}
          disabled={loading}
          required={true}
        />
        <BottomToolbarWrapper>
          <ToolbarItem>
            <ButtonLoading
              fullWidth
              color="primary"
              loading={loading}
              submit={handleSubmit}
            >
              Продолжить
            </ButtonLoading>
          </ToolbarItem>
          <ToolbarSeparator />
        </BottomToolbarWrapper>
      </form>
    </React.Fragment>
  );
};

export default EmployeeForm;
