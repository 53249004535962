import {baseGetRequestWithCount} from "../base/baseGetRequestWithCount";
import {passportType} from "../passport/getPassports";

export type bonuscardType = {
  id: number;
  PotentionalClient: number;
};

export type potentionalClientType = {
  id: number;
  last_name: string;
  first_name: string;
  patronymic: string;
  gender: "M" | "F";
  birth_date: string;
  phone: string;
  phone2?: string;
  email: string;
  Employee?: number;
  bonuscard?: bonuscardType;
  passport?: passportType | null;
  add_date?: string;
};

export type PotentionalCLientFilterType = {
  last_name: string | null;
  first_name: string | null;
  patronymic: string | null;
  birth_date: string | null;
}

const getPotentionalClients = async (
  page?: number,
  page_size?: number,
  sort_field?: string,
  sort_desc?: boolean,
  filters?: PotentionalCLientFilterType,
) => {
  let limit = 0
  let offset = 0
  if (page && page_size) {
    limit = page_size;
    offset = page * page_size - page_size;
  }
  let ordering = sort_field ? sort_field : 'last_name'
  sort_desc ? ordering = `${ordering}` : ordering = `-${ordering}`

  return baseGetRequestWithCount<potentionalClientType>(
    `/potentional_client/`, {
    limit,
    offset,
    ordering,
    ...filters,
  });
}


export default getPotentionalClients;
