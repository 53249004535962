import {Dialog, DialogProps, Paper, PaperProps} from "@mui/material";
import Draggable from "react-draggable";

const DraggableDialog = ({...props}: DialogProps) => (
  <Dialog
    {...props}
    PaperComponent={(props: PaperProps) => (
      <Draggable
        handle={`#${props["aria-labelledby"]}`}
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    )}
  >
    {props.children}
  </Dialog>
);

export default DraggableDialog;
