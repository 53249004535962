import {Proxy} from "../../../settings";
import {basePostMultipleRequest} from "../base/basePostMultipleRequest";
import {prePotentionalClientType} from "./getPrePotentionalClients";

const postPrePotentionalClientAdd = async (count: number, purchaseId: number) =>
  basePostMultipleRequest<prePotentionalClientType>(
    `${Proxy}/pre_potentional_client/add/`,
    {
      count,
      Purchase_id: purchaseId,
    }
  )

export default postPrePotentionalClientAdd