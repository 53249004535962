import {Box, Fade, ListItemIcon, Typography, useTheme} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ComponentType, useRef} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import theme from "../../../theme";

export const leftPaneStyleParams = {
  panelWrapped: {
    width: 60 + 16
  },
  groupIcon: {
    width: 48,
    height: 48,
    marginTop: 12,
    wrapped: {}
  },
  groupIconWrapped: {
    width: 60,
    height: 60,
    marginTop: 0
  },
  accordion: {
    paddingHor: theme.spacing(1)
  }
};

const useStyles = makeStyles()(theme => ({
  link: {
    height: "32px"
  },
  groupLink: {
    padding: "8px 0 8px 8px"
  },
  text: {
    color: theme.palette.common.black
  }
}));

type LeftPanelListItemProps = {
  url: string;
  title: string;
  name: string;
  style?: React.CSSProperties;
};

export const LeftPanelListItem = (props: LeftPanelListItemProps) => {
  const {classes} = useStyles();

  return (
    <ListItem
      activeClassName="Mui-selected"
      component={NavLink}
      to={props.url}
      id={`left_panel_${props.name}`}
      className={classes.link}
      style={props.style}
      exact
    >
      <ListItemText primary={props.title} className={classes.text} />
    </ListItem>
  );
};

export type IconComponentType = ComponentType<{
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}>;

type LeftPanelGroupItemProps = {
  title: string;
  activated: boolean;
  icon: IconComponentType;
  leftPanelOpen: boolean;
};

export const LeftPanelGroupItem = (props: LeftPanelGroupItemProps) => {
  const prevOpen = useRef(props.leftPanelOpen);
  const transitionTimeout = prevOpen.current !== props.leftPanelOpen ? 300 : 0;
  prevOpen.current = props.leftPanelOpen;

  const Icon = props.icon;

  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <ListItemIcon
        style={{
          minWidth: "24px",
          marginTop: props.leftPanelOpen
            ? leftPaneStyleParams.groupIcon.marginTop
            : leftPaneStyleParams.groupIconWrapped.marginTop,
          transition: "marginTop 300ms"
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={
            props.leftPanelOpen
              ? leftPaneStyleParams.groupIcon.width
              : leftPaneStyleParams.groupIconWrapped.width
          }
        >
          <Icon color={props.activated ? "primary" : undefined} />

          <Fade in={!props.leftPanelOpen} timeout={transitionTimeout}>
            <Typography
              color={props.activated ? "primary" : theme.palette.common.black}
              style={{fontSize: 11}}
            >
              {props.leftPanelOpen ? props.title : ""}
            </Typography>
          </Fade>
        </Box>
      </ListItemIcon>
      <Fade
        in={props.leftPanelOpen}
        timeout={transitionTimeout}
        mountOnEnter
        unmountOnExit
      >
        <Typography
          color={props.activated ? "primary" : theme.palette.common.black}
          style={{fontWeight: props.activated ? "bold" : "normal"}}
        >
          {props.title}
        </Typography>
      </Fade>
    </Box>
  );
};

export const LeftPanelGroupListItem = (
  props: LeftPanelListItemProps & {
    icon: IconComponentType;
    leftPanelOpen: boolean;
  }
) => {
  const location = useLocation();
  const activated = location.pathname === props.url;

  const {classes} = useStyles();

  return (
    <ListItem
      activeClassName="Mui-selected"
      component={NavLink}
      to={props.url}
      id={`left_panel_${props.name}`}
      className={classes.groupLink}
      style={{
        transition: "height 300ms ",
        height: props.leftPanelOpen
          ? leftPaneStyleParams.groupIcon.height
          : leftPaneStyleParams.groupIconWrapped.height
      }}
      exact
    >
      <LeftPanelGroupItem activated={activated} {...props} />
    </ListItem>
  );
};
