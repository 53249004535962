import {loginType} from "../APIRequests/login/postLogin";

const getLoginInfo: Function = (): loginType | {} => {
  const authStorage = localStorage.getItem("auth");
  if (!authStorage) {
    return {};
  }
  try {
    return JSON.parse(authStorage) as loginType;
  } catch (error) {
    return {};
  }
};
export default getLoginInfo;
