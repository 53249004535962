import {DialogContent, Theme} from "@mui/material";
import {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {modeType} from "../../../../common/APIRequests/mode/getModes";
import {prePotentionalClientType} from "../../../../common/APIRequests/prePotentionalClient/getPrePotentionalClients";
import postPrePotentionalClient from "../../../../common/APIRequests/prePotentionalClient/postPrePotentionalClient";
import {purchaseType} from "../../../../common/APIRequests/purchase/getPurchases";
import {Transition} from "../../../common/components";
import DraggableDialog from "../../../common/dialog/DraggableDialog";
import DraggableDialogTitle from "../../../common/dialog/DraggableDialogTitle";
import PrePotentionalTable from "./PrePotentionalTable";

export const PREPOTENTIONAL_CLIENT_COUNT = 5;

const useStyles = makeStyles()((theme: Theme) => ({
  scrollPaper: {
    alignItems: "flex-start"
  }
}));

type Props = {
  purchase: purchaseType;
  selectedMode: modeType | undefined;
};

const PrePotentional = (props: Props) => {
  const {selectedMode, purchase} = props;

  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    selectedMode && selectedMode.need_additional_clients && setOpen(true);
  }, [selectedMode]);

  const [prePotentionalClients, setPrepPotentionalClients] = useState<
    Array<prePotentionalClientType>
  >([]);
  useEffect(() => {
    prePotentionalClients.length === PREPOTENTIONAL_CLIENT_COUNT &&
      setOpen(false);
  }, [prePotentionalClients.length]);

  const updatePClients = (pClient: prePotentionalClientType) => {
    pClient.id = prePotentionalClients.length + 1;
    const alreadyHas = prePotentionalClients.filter(
      (pPC: prePotentionalClientType) => !(pClient.id === pPC.id)
    );
    setPrepPotentionalClients([...alreadyHas, pClient]);
  };

  useEffect(() => {
    createClients();
  }, [purchase.id]);
  const createClients = async () => {
    prePotentionalClients.forEach(async (c: prePotentionalClientType) => {
      await postPrePotentionalClient(c);
    });
    setPrepPotentionalClients([]);
  };

  const {classes: localClasses} = useStyles();

  return (
    <>
      {open && (
        <DraggableDialog
          open
          keepMounted
          TransitionComponent={Transition}
          maxWidth="md"
          aria-labelledby="pre-potentional-modal-open"
          classes={{scrollPaper: localClasses.scrollPaper}}
        >
          <DraggableDialogTitle id="pre-potentional-modal-open">
            Укажите {PREPOTENTIONAL_CLIENT_COUNT} потенциальных клиентов
          </DraggableDialogTitle>
          <DialogContent>
            <PrePotentionalTable
              prePotentionalClients={prePotentionalClients}
              update={updatePClients}
            />
          </DialogContent>
        </DraggableDialog>
      )}
    </>
  );
};

export default PrePotentional;
