import {Proxy} from "../../../settings";
import {productPurchaseType} from "./getProductPurchases";
import {basePostRequest} from "../base/basePostRequest";

const postProductPurchases = async (data: Array<productPurchaseType>) =>
  basePostRequest<Array<productPurchaseType>>(
    `${Proxy}/product_purchase/`,
    data
  );

export default postProductPurchases;
