import * as React from "react";
import {employeeType} from "../common/APIRequests/employee/getEmployee";

export type AppContextType = {
  setAlert: Function;
  updateEmployee: Function;
  prePotentionalCount: number | number[];
  updatePrePotentionalCount: () => void;
  employee: employeeType | undefined;
  loading: boolean;
};

export const AppContextProps: AppContextType = {
  setAlert: () => {},
  updateEmployee: () => {},
  prePotentionalCount: 0,
  updatePrePotentionalCount: () => {},
  employee: undefined,
  loading: false
};

export const AppContext = React.createContext(AppContextProps);
