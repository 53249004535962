import {Proxy} from "../../../settings";
import {basePostRequest} from "../base/basePostRequest";

export type userType = {
  id: number;
  username: string;
  email: string;
};

const postUser = (data: {username: string; password: string; email: string}) =>
  basePostRequest<userType>(`${Proxy}/create_user/`, data);

export default postUser;
