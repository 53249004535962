import {Proxy} from "../../../settings";
import {basePostRequest} from "../base/basePostRequest";
import {prePotentionalClientType} from "./getPrePotentionalClients";

const postPrePotentionalClient = async (data: prePotentionalClientType) =>
  basePostRequest<prePotentionalClientType>(
    `${Proxy}/pre_potentional_client/`,
    data
  );

export default postPrePotentionalClient;
