import {errorType} from "../../../components/common/types";
import {apiClient} from "../../utils/apiClient";

export function basePostRequest<T>(
  url: string,
  data: undefined | any
): Promise<{
  success: boolean;
  row: T | undefined;
  errors: errorType;
}> {
  return new Promise(resolve =>
    apiClient({
      method: "post",
      url,
      data
    })
      .then(response =>
        resolve({
          success: true,
          row: response.data as T,
          errors: {}
        })
      )
      .catch(errors => {
        resolve({
          success: false,
          row: undefined,
          errors
        });
      })
  );
}
