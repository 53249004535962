import {Badge} from "@mui/material";
import React from "react";

type Props = {
  bageValue: number | number[];
  children: any;
};

const BagedIcon = React.forwardRef((props: Props, ref: any) => {
  const {bageValue} = props;
  return (
    <Badge badgeContent={bageValue} color="primary" ref={ref}>
      {props.children}
    </Badge>
  );
});

export default BagedIcon;
