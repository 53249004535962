import {Tab, TabProps, Tabs} from "@mui/material";
import {makeStyles, withStyles} from "tss-react/mui";

export const StyledTabs = withStyles(Tabs, theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}));

const tabStyles = makeStyles()(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.light,
      opacity: 1
    },
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: theme.palette.primary.main
    }
  },
  selected: {}
}));

export const StyledTab = (props: TabProps) => {
  const {classes} = tabStyles();
  return <Tab disableRipple {...props} className={classes.root} />;
};
