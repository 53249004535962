import {CircularProgress, Paper} from "@mui/material";
import moment from "moment";
import {Fragment, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {employeeType} from "../../common/APIRequests/employee/getEmployee";
import generateStructureXlsx from "../../common/APIRequests/generateXlsx/generateXlsx";
import getStructure, {
  structureType
} from "../../common/APIRequests/getStructure/getStructure";
import smashTree from "../../common/smashTree";
import {Proxy} from "../../settings";
import {AppContext, AppContextType} from "../App-Context";
import {defaultEmployee} from "../addEmployee/EmployeeForm";
import {
  BottomToolbarWrapper,
  ToolbarItem,
  ToolbarSeparator
} from "../common/Toolbar";
import ButtonLoading from "../common/buttons/ButtonLoading";
import SelectSingleField from "../common/formFields/SelectSingleField";
import StringField from "../common/formFields/StringField";
import {monthOptions} from "../common/options";
import {useGlobalStyles} from "../common/styles";
import {alertType, errorType} from "../common/types";
import StructureTableNew from "./StructureTable";

const useStyles = makeStyles()((theme: any) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1500 + theme.spacing(2) * 2)]: {
      width: 1500,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1500 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

const PrintStructure = () => {
  const appContext: AppContextType = useContext(AppContext);
  const {setAlert, employee, updateEmployee} = appContext;

  const [loading, setLoading] = useState(false as boolean);

  const [structureEmployees, setStructureEmployees] = useState(
    [] as Array<employeeType>
  );
  useEffect(() => {
    if (employee && employee.children) {
      const sEs = [] as Array<employeeType>;
      smashTree(employee, sEs);
      setStructureEmployees(sEs);
    }
  }, [employee]);

  const [selectedEmployee, setSelectedEmployee] = useState(
    defaultEmployee as employeeType
  );

  const [month, setMonth] = useState((moment().month() + 1) as number);

  const [year, setYear] = useState(moment().year() as number);

  const [structure, setStructure] = useState([] as Array<Array<structureType>>);

  const [excelLink, setExcelLink] = useState(undefined as string | undefined);

  const [errors, setErrors] = useState({} as errorType);

  const handleChange = (name: string) => (value: string) =>
    setSelectedEmployee({...selectedEmployee, [name]: value});

  const handleSubmit = async () => {
    setErrors({});
    setLoading(true);
    setStructure([]);
    setExcelLink(undefined);

    if (selectedEmployee.id) {
      const result: Array<structureType> = [];

      const getStructureResult = await getStructure(
        selectedEmployee.id,
        month,
        year
      );

      if (getStructureResult.success) {
        const structure = getStructureResult.rows;

        //@ts-ignore
        const generateStructureXlsxResult = await generateStructureXlsx(
          structure,
          month,
          year
        );

        if (generateStructureXlsxResult.success) {
          //@ts-ignore
          setStructure(structure);
          setExcelLink(`${Proxy}${generateStructureXlsxResult.link}`);
        }
      }
    } else {
      setAlert({
        severity: "error",
        message: ["ФИО сотрудника не выбрано "]
      } as alertType);
      setErrors({...errors, id: ["Необходимо выбрать значение"]});
    }
    setLoading(false);
  };

  const {classes: localClasses} = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Fragment>
      <main className={localClasses.layout}>
        <Paper className={localClasses.paper}>
          <SelectSingleField
            setValue={handleChange("id")}
            value={selectedEmployee.id ? selectedEmployee.id.toString() : ""}
            name="id"
            label="ФИО сотрудника"
            errors={errors}
            setErrors={setErrors}
            disabled={loading}
            // @ts-ignore
            options={structureEmployees.map((employee: employeeType) => ({
              key: employee.id,
              text: `${employee.last_name} ${employee.first_name} ${employee.patronymic}`,
              value: employee.id
            }))}
          />
          <SelectSingleField
            setValue={(value: string) => setMonth(parseInt(value))}
            value={month.toString()}
            name="month"
            label="Месяц"
            errors={errors}
            setErrors={setErrors}
            disabled={loading}
            options={monthOptions}
          />
          <StringField
            setValue={(value: string) => setYear(parseInt(value))}
            value={year.toString()}
            name="year"
            label="Год"
            errors={errors}
            setErrors={setErrors}
            disabled={loading}
          />
          <BottomToolbarWrapper>
            {excelLink && (
              <ToolbarItem>
                <ButtonLoading
                  fullWidth
                  color="primary"
                  loading={loading}
                  submit={() => excelLink && (window.location.href = excelLink)}
                >
                  Сохранить в Excel
                </ButtonLoading>
              </ToolbarItem>
            )}
            <ToolbarItem>
              <ButtonLoading
                fullWidth
                color="primary"
                loading={loading}
                submit={handleSubmit}
              >
                Сформировать
              </ButtonLoading>
            </ToolbarItem>
            <ToolbarSeparator />
          </BottomToolbarWrapper>
          {structure.length > 0 &&
            structure.map((s: Array<structureType>, index: number) => (
              <StructureTableNew structure={s} key={index} />
            ))}
          {loading && (
            <CircularProgress className={globalClasses.tableProgress} />
          )}
        </Paper>
      </main>
    </Fragment>
  );
};

export default PrintStructure;
