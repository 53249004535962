import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import getModes, {modeType} from "../../../../common/APIRequests/mode/getModes";
import {productType} from "../../../../common/APIRequests/product/getProducts";
import {purchaseType} from "../../../../common/APIRequests/purchase/getPurchases";
import {AppContext} from "../../../App-Context";
import BooleanField from "../../../common/formFields/BooleanField";
import PickerDate from "../../../common/formFields/PickerDate";
import SelectSingleField from "../../../common/formFields/SelectSingleField";
import StringField from "../../../common/formFields/StringField";
import {paymentOptions} from "../../../common/options";
import {errorType, optionType} from "../../../common/types";
import computePayments from "../../computePayments";
import PromoCodeForm from "./PromoCodeForm";
import ProductTabs from "./Tabs/ProductTabs";

export const defaultPurchase = {
  id: undefined,
  payment_count: 0,
  delivery_count: 0,
  payment_date: moment().format("DD-MM-YYYY"),
  first_payment: 0,
  month_payment: 0,
  delivery_address: "",
  PotentionalClient: undefined
} as purchaseType;

type Props = {
  passport_address?: string;
  products: Array<productType>;
  setProducts: Function;
  purchase: purchaseType;
  setPurchase: Function;
  errors: errorType;
  setErrors: (errors: errorType) => void;
  selectedMode: modeType | undefined;
  setSelectedMode: Function;
};

const PurchaseForm = (props: Props) => {
  const {
    passport_address,
    products,
    setProducts,
    purchase,
    setPurchase,
    errors,
    setErrors,
    selectedMode,
    setSelectedMode
  } = props;

  const appContext = React.useContext(AppContext);
  const {setAlert} = appContext;

  const [loading, setLoading] = useState(false as boolean);

  useEffect(() => {
    (async () => {
      const result = await getModes();

      if (result.success && Array.isArray(result.rows)) {
        setModes(result.rows);
        setModeOptions(
          result.rows.map((m: modeType) => ({
            key: m.id,
            value: m.id,
            text: m.name
          }))
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (!Boolean(passport_address)) {
      setPurchase({...purchase, delivery_address: ""});
      setMatchWithPasswordAddress(false);
    }
  }, [passport_address]);

  useEffect(() => {
    if (products.length) {
      updatePayments(purchase.payment_count);
    }
  }, [products]);

  useEffect(() => {
    if (!purchase.delivery_address) {
      setMatchWithPasswordAddress(false);
    }
  }, [purchase.delivery_address]);

  useEffect(() => {
    if (purchase.payment_count === 0) {
      updatePayments(1);
    }
  }, [purchase.payment_count]);

  const [modes, setModes] = useState([] as Array<modeType>);
  const [modeOptions, setModeOptions] = useState([] as Array<optionType>);
  useEffect(() => {
    if (selectedMode && selectedMode.is_under_password) {
      setModeModalOpen(true);
    }
    setProducts([]);
    selectedMode && updatePaymentOptions(selectedMode);
  }, [selectedMode]);
  const [modePaymentOptions, setModePaymentOptions] = useState(
    [] as Array<optionType>
  );
  const [modeModalOpen, setModeModalOpen] = useState(false as boolean);

  const [matchWithPasswordAddress, setMatchWithPasswordAddress] = useState(
    false as boolean
  );
  useEffect(() => {
    if (matchWithPasswordAddress && passport_address) {
      setPurchase({...purchase, delivery_address: passport_address});
    }
  }, [matchWithPasswordAddress]);

  /**
   * @description compute payments
   * @param payment_count
   */
  const updatePayments = (payment_count: number) => {
    const {firstPayment, monthPayment} = computePayments(
      products,
      payment_count
    );

    setPurchase({
      ...purchase,
      payment_count,
      first_payment: firstPayment,
      month_payment: monthPayment
    });
  };

  /**
   * @description remove product
   * @param e
   * @param productId
   */
  const handleProductDeselect = (productId: number) => {
    const {payment_count} = purchase;

    let newProducts = products.filter(p => p.id !== productId);

    const {firstPayment, monthPayment} = computePayments(
      newProducts,
      payment_count
    );

    setProducts(newProducts);
    setPurchase({
      ...purchase,
      first_payment: firstPayment,
      month_payment: monthPayment
    });
  };

  /**
   * @description updates count of Products
   * @param productId
   * @param count
   */
  const handleProductCountUpdate = (productId: number, count: number) => {
    const product = products.filter(p => p.id === productId)[0];
    product.count = count;

    const newProducts = [...products.filter(p => p.id !== productId), product];

    const {firstPayment, monthPayment} = computePayments(
      newProducts,
      purchase.payment_count
    );

    setProducts(newProducts);
    setPurchase({
      ...purchase,
      first_payment: firstPayment,
      month_payment: monthPayment
    });
  };

  const handleChange = (name: string) => (value: string) =>
    setPurchase({...purchase, [name]: value});

  const handleChangePaymentCount = (name: string) => (value: string) => {
    updatePayments(parseInt(value));
  };

  /**
   * @description handles promoCode submit
   */
  const handlePromoCodeSubmit = (promoCode: string) => {
    const matched = modes.filter(
      (mode: modeType) => mode.password === promoCode
    );

    if (matched.length) {
      setSelectedMode(matched[0]);

      const selectedMode = matched[0];

      updatePaymentOptions(selectedMode);

      setModeModalOpen(false);
    } else {
      setErrors({promoCode: ["Некорректный промокод"]});
    }
  };

  const updatePaymentOptions = (selectedMode: modeType) => {
    let modeCount: number = 0;
    let modePaymentOptions: Array<optionType>;

    if (selectedMode) {
      const {payment_count, max_payment_count} = selectedMode;

      if (max_payment_count) {
        modePaymentOptions = paymentOptions.filter(
          (o: optionType) =>
            //@ts-ignore
            o.key && o.key >= payment_count && o.key <= max_payment_count
        );
      } else {
        modePaymentOptions = paymentOptions.filter(
          //@ts-ignore
          (o: optionType) => o.key && o.key >= payment_count && o.key <= 12
        );
      }

      setPurchase({...purchase, payment_count: modeCount});
      setModePaymentOptions(modePaymentOptions);
    }
  };

  const handleMatchWithPasswordAddress = (value: string) => {
    value === "1"
      ? setMatchWithPasswordAddress(true)
      : setMatchWithPasswordAddress(false);
  };

  return (
    <Fragment>
      {modeModalOpen && (
        <PromoCodeForm
          modeModalOpen={modeModalOpen}
          setModeModalOpen={setModeModalOpen}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          setSelectedMode={setSelectedMode}
          handlePromoCodeSubmit={handlePromoCodeSubmit}
        />
      )}
      <SelectSingleField
        value={
          selectedMode && selectedMode.id ? selectedMode.id.toString() : ""
        }
        setValue={(value: number) =>
          setSelectedMode(modes.filter((m: modeType) => m.id === value)[0])
        }
        name="selectedMode"
        label="Вид продаж для клиента"
        errors={errors}
        setErrors={setErrors}
        //@ts-ignore
        options={modeOptions}
        disabled={loading}
      />
      <ProductTabs
        products={products}
        setProducts={setProducts}
        modeId={selectedMode && selectedMode.id}
        onProductCountUpdate={handleProductCountUpdate}
        onProductDeselect={handleProductDeselect}
      />
      <SelectSingleField
        value={purchase.payment_count.toString()}
        setValue={handleChangePaymentCount("payment_count")}
        name="payment_count"
        label="Количество платежей за выбранные товары"
        errors={errors}
        setErrors={setErrors}
        //@ts-ignore
        options={modePaymentOptions}
        disabled={loading}
      />
      <PickerDate
        //@ts-ignore
        setValue={handleChange("payment_date")}
        value={purchase.payment_date}
        name="payment_date"
        label="Дата первого платежа (дд-мм-гггг)"
        minDate={moment().toDate()}
        maxDate={moment()
          .add(10, "days")
          .toDate()}
        errors={errors}
        disabled={loading || loading}
        required
      />
      <StringField
        value={purchase.first_payment.toString()}
        setValue={handleChange("first_payment")}
        name="first_payment"
        label="Первая оплата (руб.)"
        errors={errors}
        setErrors={setErrors}
        disabled
      />
      <StringField
        value={purchase.month_payment.toString()}
        setValue={handleChange("month_payment")}
        name="month_payment"
        label="Ежемесячная оплата (руб.)"
        errors={errors}
        setErrors={setErrors}
        disabled
      />
      <StringField
        value={
          matchWithPasswordAddress && passport_address
            ? passport_address
            : purchase.delivery_address
        }
        setValue={handleChange("delivery_address")}
        name="delivery_address"
        label="Адрес доставки"
        errors={errors}
        setErrors={setErrors}
        disabled={matchWithPasswordAddress || loading}
        required
      />
      <BooleanField
        setValue={handleMatchWithPasswordAddress}
        name="matchWithPasswordAddress"
        value={matchWithPasswordAddress ? "1" : "0"}
        label="Совпадает с адресом прописки"
        disabled={loading || !passport_address}
        errors={errors}
        setErrors={setErrors}
      />
    </Fragment>
  );
};

export default PurchaseForm;
